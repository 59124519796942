import React, { useContext, useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { useNavigate, useParams } from "react-router";
import images from "../../../images";
import "./PaymentCancel.css"; // Import the CSS file
import { UserContext } from "../../../providers/UserContext";
import { getSubscriptionByIdentifier } from "../../../services/subscription.service";

const PaymentCancel = () => {
  const { paymentId } = useParams(); // Get paymentId from the route
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [transactionDetails, setTransactionDetails] = useState(""); // State for transaction data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const backClick = () => {
    navigate("/practice-dashboard");
  };

  useEffect(() => {
    // Push a new entry to the history stack on mount
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Check if the popstate event is triggered
      if (event.state) {
        // Redirect to the current page to prevent back navigation
        window.history.pushState(null, document.title, window.location.href);
        // Optionally, you can redirect to a specific page
        // window.location.replace("/your-specific-page");
      }
    };

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    // Fetch transaction details from the API
    const fetchTransactionDetails = async () => {
      try {
        const details = await getSubscriptionByIdentifier(
          paymentId,
          currentUser.access
        ); // Call the service
        // console.log(details,"details");
        setTransactionDetails(details.payment[0] ?? ""); // Set transaction data
        setLoading(false);
      } catch (err) {
        // setError("Failed to fetch transaction details. Please try again.");
        setLoading(false);
      }
    };

    if (paymentId) {
      fetchTransactionDetails();
    }
  }, [paymentId, currentUser]);

  return (
    <div className="plan-details-container">
      <HeaderComponent />

      <div className="plan-details-cancel-content">
        <div className="card payment-cancel-card">
          <div className="card-body">
            {loading ? (
              <p>Loading transaction details...</p>
            ) : error ? (
              <p className="error-message">{error}</p>
            ) : (
              <>
                <img
                  src={images.paymentError}
                  alt="Payment Error"
                  className="payment-error-image"
                />
                <h2 className="plan-title payment-cancel-title">
                  Payment Cancelled
                </h2>
                <p className="payment-cancel-message">
                  Your payment process was not completed. You can return to the
                  dashboard and try again if needed.
                </p>
                <button
                  className="btn btn-primary back-to-dashboard-btn"
                  onClick={backClick}
                >
                  Back To Dashboard
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PaymentCancel;
