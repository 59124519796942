import React, { useContext, useEffect, useState } from "react";
import images from "../../../images";
import "./PurchaseStorage.css";
import Footer from "../../../components/Footer/Footer";
import HeaderComponent from "../../../components/Header/Header";
import { useNavigate } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import {
  getPlans,
  saveSubscription,
} from "../../../services/subscription.service";
import { UserContext } from "../../../providers/UserContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const PurchaseStorage = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //   const plans = [
  //     {
  //       id: 1,
  //       name: "Basic Plan",
  //       price: 19.00,
  //       practice: 10,
  //       priceId:"price_1QWu9RGbC7iSAhr2kH227DkS",
  //       type: "subscription",
  //       storage: "500 MB",
  //       billingInterval: "Monthly",
  //       details: "AI generated Vocabulary, AI generated Transcript, AI generated Audio"
  //     },
  // ];

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansData = await getPlans(currentUser.access);
        // console.log("plansData", plansData);
        const subscriptionPlans = plansData.filter(
          (plan) => plan.type === "Storage"
        );
        // console.log("data", subscriptionPlans);
        setPlans(subscriptionPlans);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching plans:", err);
        setError("Failed to load plans. Please try again later.");
        setLoading(false);
      }
    };

    fetchPlans();
  }, [currentUser]);

  const handleCheckout = async (plan) => {
    try {
      // Call the service to save the order details
      const sendToData = {
        email: currentUser.email,
        subscriptionId: plan.id,
      };
      const response = await saveSubscription(sendToData, currentUser.access);
      console.log("response", response.subscription_detail.identifier);
      window.location.href = response.checkout_url;
      // Proceed with Stripe checkout
      // const paymentId = response.subscription_detail.identifier;
      // const stripe = await stripePromise;
      // await stripe.redirectToCheckout({
      //   lineItems: [
      //     { price: "price_1QWuDaGbC7iSAhr2125o1uzv", quantity: 1 }, // Replace with your Price ID
      //   ],
      //   clientReferenceId: paymentId, // Use this to identify the payment in webhooks
      //   mode: "payment",
      //   successUrl: `http://localhost:3000/payment-success/${paymentId}`,
      //   cancelUrl: `http://localhost:3000/payment-cancel/${paymentId}`,
      //   // metadata: {
      //   //     payment_id: paymentId, // Custom value
      //   //   },
      // });
    } catch (error) {
      console.error("Error during checkout:", error);
      // Optionally show an error message to the user
    }
  };

  return (
    <div className="min-screen purchase-storage">
      <HeaderComponent />

      <div className="mainContentBox purchase-storage-main-div">
        <div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Additional Storage</h5>
              <div className="row">
                {plans.map((plan) => (
                  <div key={plan.id} className="col-lg-4 col-md-6 m-auto">
                    <div className="purchase-card">
                      <p>{plan.name}</p>

                      <h3>${plan.price}</h3>
                      {/* <ul className="puls-list">
                        {plan.details.split(",").map((detail, index) => (
                          <li key={index}>
                            <p>
                              <img src={images.checked} alt="checked" />
                              {detail}
                            </p>
                          </li>
                        ))}
                      </ul> */}
                      {/* <p>Additional Storage</p> */}
                      <button
                        className="btn btn-light"
                        onClick={() => handleCheckout(plan)}
                      >
                        Buy Now
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PurchaseStorage;
