import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import images from "../../../images";
import "./PaymentSuccess.css";
import { UserContext } from "../../../providers/UserContext";
import HeaderComponent from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { getSubscriptionByIdentifier } from "../../../services/subscription.service";
import SubscriptionModal from "../../../components/Modal/Subscription/Subscription";
import StorageAndPracticeLimit from "../../../components/Modal/StorageAndPracticeLimit/StorageAndPracticeLimit";
import { FormContext } from "../../../providers/FormContext";
import GeneratePracticeButton from "../../../components/GeneratePractise/GeneratePracticeButton";
import { fetchTopicsService } from "../../../services/vocab.service";
import { PracticeContext } from "../../../providers/PractiseContext";

const PaymentSuccess = (totalPractise, totalStorage) => {
  const { paymentId } = useParams(); // Get paymentId from the route
  const navigate = useNavigate();
  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const { resetFormData, updateFormData } = useContext(FormContext);
  const [noData, setNoData] = useState(false);
  const { setPracticeCount, setStorageUsed } = useContext(PracticeContext);
  const [transactionDetails, setTransactionDetails] = useState(""); // State for transaction data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [topics, setTopics] = useState([]);
  const [
    showStorageAndPracticeLimitModal,
    setShowStorageAndPracticeLimitModal,
  ] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const backClick = () => {
    navigate("/practice-dashboard");
  };

  useEffect(() => {
    // Push a new entry to the history stack on mount
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Check if the popstate event is triggered
      if (event.state) {
        // Redirect to the current page to prevent back navigation
        window.history.pushState(null, document.title, window.location.href);
        // Optionally, you can redirect to a specific page
        // window.location.replace("/your-specific-page");
      }
    };

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [currentUser]);

  useEffect(() => {
    // Fetch transaction details from the API
    const fetchTransactionDetails = async () => {
      try {
        const details = await getSubscriptionByIdentifier(
          paymentId,
          currentUser.access
        ); // Call the service

        setTransactionDetails(details.payment[0] ?? ""); // Set transaction data

        // Update currentUser only if subscription_status is false or undefined
        if (!currentUser.subscription_status) {
          const updated = {
            ...currentUser,
            subscription_status: true,
          };
          updateCurrentUser(updated);
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    if (paymentId) {
      fetchTransactionDetails();
    }

    // Only trigger when paymentId changes
  }, [paymentId, currentUser.access]);

  return (
    <div className="plan-details-success-container">
      <HeaderComponent />

      <div className="plan-details-success-content">
        <div className="card payment-success-card">
          <div className="card-body">
            {loading ? (
              <p>Loading transaction details...</p>
            ) : error ? (
              <p className="error-message">{error}</p>
            ) : (
              <>
                <img
                  src={images.PaymentSuccess}
                  alt="Payment Success"
                  className="payment-success-image"
                />
                <h2 className="plan-title payment-success-title">
                  Payment Success!
                </h2>
                <p className="payment-success-message">
                  Congratulations on investing in your training!
                </p>
                {/* {transactionDetails && (
                  <div className="transaction-details">
                    <p>
                      <strong>Transaction ID:</strong>{" "}
                      {transactionDetails.transaction_id &&
                      transactionDetails.transaction_id.length > 30
                        ? `${transactionDetails.transaction_id.slice(0, 30)}...`
                        : transactionDetails.transaction_id}
                    </p>
                    <p>
                      <strong>Amount:</strong> ${transactionDetails.price}
                    </p>
                    <p>
                      <strong>Status:</strong> {transactionDetails.status}
                    </p>
                  </div>
                )} */}

                <div>
                  <GeneratePracticeButton
                    setLanguageDefaultValue={() => {}}
                    setShowStorageAndPracticeLimitModal={
                      setShowStorageAndPracticeLimitModal
                    }
                    setShowSubscriptionModal={setShowSubscriptionModal}
                    isListItem={false}
                  />

                  <button
                    className="btn btn-success back-to-dashboard-btn-success"
                    onClick={backClick}
                  >
                    Go to Dashboard
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Footer />
      {showStorageAndPracticeLimitModal && (
        <StorageAndPracticeLimit
          show={showStorageAndPracticeLimitModal}
          handleClose={() => setShowStorageAndPracticeLimitModal(false)}
        />
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          show={showSubscriptionModal}
          onHide={() => setShowSubscriptionModal(false)}
        />
      )}
    </div>
  );
};

export default PaymentSuccess;
