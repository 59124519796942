import React, { useContext, useState } from "react";
import "./ProfileInfo.css";
import { UserContext } from "../../../../providers/UserContext";
import UserProfileUpdate from "../../../../components/Modal/UserProfileUpdate/UserProfileUpdate";

const ProfileInfo = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const { currentUser } = useContext(UserContext);
  return (
    <div className="profile-info">
      <div className="profile-info-header-content">
        <h3>Profile Information</h3>
        <button className="profile-edit-button" onClick={openModal}>
          Edit
        </button>
      </div>
      <table>
        <tbody>
          <tr>
            <th>First Name:</th>
            <td>{currentUser.first_name}</td>
          </tr>

          <tr>
            <th>Last Name:</th>
            <td>{currentUser.last_name}</td>
          </tr>
          <tr>
            <th>Email:</th>
            <td>{currentUser.email}</td>
          </tr>
        </tbody>

        {/* <tr>
          <th>Phone:</th>
          <td>+1 234 567 890</td>
        </tr> */}
      </table>
      <UserProfileUpdate isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default ProfileInfo;
