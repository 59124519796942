import React from "react";
import "./AudioListWithShare.css";
import { toast } from "react-toastify";

const AudioListWithShareModal = ({
  show,
  handleClose,
  recordings,
}) => {
  if (!show) {
    return null;
  }

  const handleCopyLink = (id, recordingId) => {
      const shareableUrl = `${window.location.origin}/share-recording/${id}/${recordingId}`;
      navigator.clipboard.writeText(shareableUrl).then(() => {
        toast.success("Your share link has been successfully created.");
      });
      console.log(shareableUrl, "shareableUrl");
  };

  return (
    <div className="audio-list-with-share-overlay">
      <div className="audio-list-with-share-modal">
        <div className="audio-list-with-share-header">
          <h2>Share Recording(s)</h2>
          <span className="audio-list-with-share-close" onClick={handleClose}>
            <i class="bi bi-x-circle"></i>
          </span>
        </div>

        <div className="audio-list-with-share-body">
          {recordings.length === 0 ? (
            <p>No recordings available to delete.</p>
          ) : (
            recordings.map((recording) => (
              <div key={recording.id} className="audio-list-with-share-item">
                <span>{recording.recording_name}</span>
                <button
                  className="audio-list-with-share-btn"
                  data-id={recording.id}
                  onClick={() => handleCopyLink(recording.identifier, recording.id)}
                >
                  Copy Link
                </button>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioListWithShareModal;
