import React, { useEffect, useState, useRef } from "react";
import "./GlowingCountdown.css"; // Import the CSS file

const GlowingCountdown = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    // Countdown timer logic
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          return "GO!";
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="body">
      <div className="overlay-text">Your practice will begin in 5 seconds.</div>
      <div className="countdown-timer">
        <div className="outer-ring"></div>
        <div className="glowing-circle">
          <svg className="svg">
            <circle className="circle" cx="110" cy="110" r="90"></circle>
          </svg>
        </div>
        <div className="countdown-number">{countdown}</div>
      </div>
    </div>
  );
};

export default GlowingCountdown;
