import React, { useState, useEffect, useContext } from "react";
import images from "../../images";
import HeaderComponent from "components/Header/Header";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { FormContext, resetFormData } from "../../providers/FormContext";
import ConfirmDeleteModal from "../../components/Modal/DeleteModal/VocabDeletModal";
import { REACT_APP_URL } from "../../constants";
import ShareModal from "../../components/Modal/ShareModal/ShareModal";
import ConfirmCopyModal from "../../components/Modal/CopyModal/VocabCopyModal";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import { deleteItem } from "../../services/api.service";
import {
  copyVocabService,
  fetchSharedTopicsService,
  fetchTopicsService,
} from "../../services/vocab.service";
import { UserContext } from "../../providers/UserContext";
import EditModal from "../../components/Modal/EditModal/EditModal";
import Loader from "../../components/Loader/Loader";
import SubscriptionModal from "../../components/Modal/Subscription/Subscription";
import { PracticeContext } from "../../providers/PractiseContext";
import BarStrip from "../../components/BarStrip/Barstrip";
import { PlanContext } from "../../providers/PlanContext";
import StorageAndPracticeLimit from "../../components/Modal/StorageAndPracticeLimit/StorageAndPracticeLimit";
import GeneratePracticeButton from "../../components/GeneratePractise/GeneratePracticeButton";

const PractiseDashboard = (totalPractise, totalStorage) => {
  const [topics, setTopics] = useState([]);
  const [sharedTopics, setSharedTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [noData, setNoData] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [itemToCopy, setItemToCopy] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [
    showStorageAndPracticeLimitModal,
    setShowStorageAndPracticeLimitModal,
  ] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { updateCurrentUser } = useContext(UserContext);
  const { id } = useParams();
  const {
    userPracticeLimit,
    userStorageLimit,
    setPracticeCount,
    setStorageUsed,
  } = useContext(PracticeContext);
  const { currentUser } = useContext(UserContext);
  // const [usertoken] = useState(currentUser.access || "");
  // const [userEmail] = useState(currentUser.email || "");
  const { resetFormData, updateFormData } = useContext(FormContext);
  const navigate = useNavigate();
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [currentTopic, setCurrentTopic] = useState(null); // For storing the selected topic
  const location = useLocation(); // Get the current location object

  // const GeneratePractiseclik = () => {
  //   if (currentUser.subscription_status) {
  //     resetFormData();
  //     setLanguageDefaultValue();
  //     if (totalpractise > 0 && totalstorage > 0) {
  //       if (
  //         totalpractise <
  //           process.env.REACT_APP_SHOW_NOTIFICATION_PRACTICE_LIMIT ||
  //         totalstorage < process.env.REACT_APP_SHOW_NOTIFICATION_STORAGE_LIMIT
  //       ) {
  //         toast.info(
  //           `You are nearing your usage limits. You have currently remaining ${totalpractise} practices and ${totalstorage} MB of storage. Please upgrade your plan to continue using this feature without interruptions.`,
  //           {
  //             className: "custom-toast",
  //             bodyClassName: "custom-toast-body",
  //             position: "top-center",
  //           }
  //         );
  //       }
  //       navigate("/vocabulary-screen");
  //     } else {
  //       setShowStorageAndPracticeLimitModal(true);
  //     }
  //   } else {
  //     setShowSubscriptionModal(true);
  //   }
  // };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sharedPractice = params.get("sharedPractice");

    setToggle(sharedPractice === "true");
  }, [location]);

  const setLanguageDefaultValue = () => {
    if (topics && topics.length > 0) {
      updateFormData("sourceLanguage", topics[0].source_language);
      updateFormData("targetLanguage", topics[0].target_language);
    }
  };

  const deleteClick = (identifier) => {
    // console.log("Deleting item with identifier:", identifier);
    setItemToDelete(identifier);
    setShowModal(true);
  };
  const handleShareOpenModal = () => {
    setIsShareModalOpen(true);
  };

  const handleShareCloseModal = () => {
    setIsShareModalOpen(false);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onDelete = async () => {
    // console.log("Item to delete:", itemToDelete);
    try {
      const result = await deleteItem(itemToDelete, currentUser.access);
      // console.log("Delete result:", result);
      if (result) {
        fetchTopics();
        const updated = {
          ...currentUser,
          totalPractice: Math.max((currentUser.totalPractice ?? 0) - 1, 0),
        };
        updateCurrentUser(updated);
      }
      toast.success(`Practice Deleted Successfully`);
      setTopics((prevTopics) =>
        prevTopics.filter((topic) => topic.identifier !== itemToDelete)
      );
    } catch (error) {
      console.error("Delete error:", error);
      setError(error.message);
    } finally {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.email) {
      fetchSharedTopics();
      fetchTopics();
      resetFormData();
    }
  }, [currentUser, toggle]);

  const fetchSharedTopics = async () => {
    setLoading(true);
    setError(null);
    setNoData(false);

    const response = await fetchSharedTopicsService(
      currentUser.email,
      currentUser.access
    );

    if (response.success) {
      setSharedTopics(response.data);
    } else if (response.noData) {
      setNoData(true);
    } else {
      setError(response.error);
    }

    setLoading(false);
  };

  const fetchTopics = async () => {
    setLoading(true);
    setError(null);
    setNoData(false);

    const response = await fetchTopicsService(
      currentUser.email,
      currentUser.access
    );

    if (response.success) {
      const practiceInfo = response.data || []; // Extract practice_info array
      const practiceCount = practiceInfo.length;

      // Calculate storage usage (20 MB per practice)
      const storageUsed = practiceCount * 20;

      // Update context values
      setPracticeCount(practiceCount);
      setStorageUsed(storageUsed);

      setTopics(response.data);
    } else if (response.noData) {
      setNoData(true);
    } else {
      setError(response.error);
    }
    setLoading(false);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const openFlashCartHandler = (id) => {
    navigate(`/game-question-and-flash-card/${id}`);
  };
  const handleEditPractice = (topic) => {
    setCurrentTopic(topic); // Store the topic
    setEditModalOpen(true); // Open the modal
  };
  const handleConfirmEdit = () => {
    if (currentTopic) {
      editPractice(currentTopic); // Call the original function to navigate
    }
    setEditModalOpen(false); // Close the modal
  };

  const handleCancelEdit = () => {
    setEditModalOpen(false); // Just close the modal
  };
  const editPractice = (topic) => {
    if (topic.response_flag === 0) {
      navigate(`/vocabulary/${topic.identifier}`);
    } else if (topic.response_flag === 1) {
      navigate(`/transcript/${topic.identifier}`);
    } else if (topic.response_flag === 2) {
      navigate(`/voice-catalog/${topic.identifier}`);
    } else {
      navigate(`/vocabulary/${topic.identifier}`);
    }
  };
  const openRecording = (id) => {
    navigate(`/recording/${id}`);
  };

  const drillVocablryClick = (id) => {
    navigate(`/game-question-and-flash-card/${id}`);
  };
  const sharedDrillVocablryClick = (id) => {
    // if (
    //   currentUser &&
    //   currentUser.first_name &&
    //   currentUser.subscription_status
    // ) {
    navigate(`/game-question-and-flash-card/${id}`);
    // } else {
    //   setShowSubscriptionModal(true);
    // }
  };
  const interpretClick = (id) => {
    navigate(`/audio-interpret/${id}`);
  };
  const sharedInterpretClick = (id) => {
    // if (
    //   currentUser &&
    //   currentUser.first_name &&
    //   currentUser.subscription_status
    // ) {
    navigate(`/audio-interpret/${id}`);
    // } else {
    //   setShowSubscriptionModal(true);
    // }
  };

  const evaluteClick = (id) => {
    navigate(`/recording-with-keyword/${id}`);
  };
  const sharedEvaluteClick = (id) => {
    // if (
    //   currentUser &&
    //   currentUser.first_name &&
    //   currentUser.subscription_status
    // ) {
    navigate(`/recording-with-keyword/${id}`);
    // } else {
    //   setShowSubscriptionModal(true);
    // }
  };

  const copyPractice = async (id) => {
    setShowCopyModal(true);
    setItemToCopy(id);
  };

  const onCopy = async () => {
    setLoading(true);
    setError(null);
    setNoData(false);

    const response = await copyVocabService(itemToCopy, currentUser.access);

    if (response.success) {
      // Assuming `fetchTopics` is needed to update the UI
      fetchTopics();
    } else {
      setError(response.error);
    }

    setLoading(false);
    setShowCopyModal(false);
  };
  const continueYourPractice = (topic) => {
    if (topic.is_complete === true) {
      if (topic.response_flag === 1) {
        navigate(`/transcript/${topic.identifier}`);
      } else if (topic.response_flag === 2 || topic.response_flag === 3) {
        navigate(`/voice-catalog/${topic.identifier}`);
      }
    } else {
      if (topic.response_flag === 1) {
        navigate(`/transcript-generation/${topic.identifier}`);
      } else if (topic.response_flag === 2 || topic.response_flag === 3) {
        navigate(`/voice-catalog/${topic.identifier}`);
      }
    }
  };

  const copyLink = (id) => {
    handleShareOpenModal();
    const link = `${REACT_APP_URL}/practice-share-link/${id}`;
    setUrl(link);
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // console.log("Text copied to clipboard successfully");
          toast.success("Share Link Successfully Copied.");
          handleCopySuccess();
        })
        .catch((err) => {
          console.error("Failed to copy using Clipboard API:", err);
          fallbackCopyToClipboard(text);
        });
    } else {
      console.error("Clipboard API not supported");
      fallbackCopyToClipboard(text);
    }
  };

  const fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to the bottom
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      console.log("Fallback: Copying text command was ");
      handleCopySuccess(successful);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const handleCopySuccess = (successful = true) => {
    setTimeout(() => {}, 2000); // Adjust the time as needed
  };

  const handleToggle = () => {
    const newUrl = toggle
      ? "/practice-dashboard?sharedPractice=false"
      : "/practice-dashboard?sharedPractice=true";
    window.history.pushState({}, "", newUrl);
    setToggle((prev) => !prev);
  };

  return (
    <div className="min-screen recordingContent">
      {/* <BarStrip
        message="An Interpreting Practice Has Been Shared With You! Happy Training!"
        isVisible={!isScrolled}
      /> */}
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content tab-section tab-section-dash">
          {/* {topics.length > 0 && (
            <button
              onClick={GeneratePractiseclik}
              className="btn btn-success"
              style={{ textTransform: "none" }}
            >
              Generate a Practice
              <img src={images.pluscircle} alt="plus-circle-icon" />
            </button>
          )} */}
          {topics.length > 0 && (
            <GeneratePracticeButton
              setLanguageDefaultValue={setLanguageDefaultValue}
              setShowStorageAndPracticeLimitModal={
                setShowStorageAndPracticeLimitModal
              }
              setShowSubscriptionModal={setShowSubscriptionModal}
              isListItem={false}
            />
          )}
          {/* <div className="card">
            <div className="card-body">
              <p className="card-text">
                You now have the capability to directly edit the vocabulary and
                transcript. Please note that if you choose to regenerate the
                audio after making edits, it will cost you one practice credit.
              </p>
              <p className="card-text text-success">
                Steps to Edit and Regenerate Audio:
              </p>
              <h2 className="recording-listTitle">
                1. Edit Vocabulary and Transcript:
              </h2>
              <ul className="recording-list">
                <li>
                  Click on the "Edit Vocabulary" or “Edit Transcript” button
                </li>
                <li>Make the necessary changes and save your edits.</li>
              </ul>
              <h2 className="recording-listTitle">2. Regenerate Audio:</h2>
              <ul className="recording-list">
                <li>
                  After saving your edits, click on the "Regenerate Audio"
                  button.
                </li>
                <li>
                  Confirm the regeneration. This action will deduct one practice
                  credit from your account..
                </li>
              </ul>
              <p className="card-text mt-2">
                Note: Ensure that you review your changes carefully before
                regenerating the audio to avoid unnecessary usage of practice
                credits.
              </p>
            </div>
          </div> */}
          {/* <div className="toggle-container"> */}
          {/* <p>Published Practices</p> */}
          {/* </div> */}
          {(topics?.length > 0 || sharedTopics?.length > 0) && (
            <div className="toggle-container">
              <p className={!toggle ? "active" : ""}>Published Practices</p>
              <div className="toggle-switch">
                <input
                  className="toggle-input"
                  id="toggle"
                  type="checkbox"
                  checked={toggle}
                  onChange={handleToggle}
                />
                <label className="toggle-label" htmlFor="toggle"></label>
              </div>
              <p className={`${toggle ? "active" : ""}`}>Shared Practices</p>
            </div>
          )}
          <div
            id="PublishedPractices"
            className={`tab-content ${!toggle ? "active" : ""}`}
          >
            <div id="PublishedPractices" className="tab-content active">
              {loading ? (
                <Loader />
              ) : // <div className="loader"></div>
              topics.length === 0 ? (
                <div className="tab-card">
                  <p>You haven't created any practices yet.</p>
                  <div className="noDecoration" style={{ marginTop: "30px" }}>
                    <GeneratePracticeButton
                      setLanguageDefaultValue={setLanguageDefaultValue}
                      setShowStorageAndPracticeLimitModal={
                        setShowStorageAndPracticeLimitModal
                      }
                      setShowSubscriptionModal={setShowSubscriptionModal}
                      isListItem={false}
                    />
                  </div>
                </div>
              ) : error ? (
                <p className="text-danger">{error}</p>
              ) : (
                topics.map((topic) => {
                  const isVocabularyCompleted =
                    topic.response_flag === 1 ||
                    topic.response_flag === 2 ||
                    topic.response_flag === 3;
                  const isTranscriptCompleted =
                    topic.response_flag === 2 || topic.response_flag === 3;
                  const isAudioCompleted = topic.response_flag === 3;
                  const isComplete = topic.is_complete === true;

                  const audio = topic.save_audio;
                  // console.log(topic.save_audio);
                  // const audio = audios.find((item) => item.audio_file_path != null);
                  // if(audio){
                  //     setWpm(audio.wpm)
                  // }

                  return (
                    <div
                      key={topic.identifier || topic.practice_name}
                      className="card"
                    >
                      <div className="dashboard-card-header">
                        <div className="dash-card">
                          <h3>{topic.practice_name} </h3>
                          {isComplete ? (
                            ""
                          ) : (
                            <span className="dash-card-status">Incomplete</span>
                          )}
                        </div>
                        <div className="edit-delete">
                          {isComplete && isAudioCompleted ? (
                            <>
                              {/* <button
                                className="copybutton"
                                style={{ marginRight: "15px" }}
                                onClick={() => copyPractice(topic.identifier)}
                              >
                                <img
                                  src={images.copy}
                                  alt="copy-icon"
                                  className="das-copy"
                                />
                                Copy
                              </button> */}
                              <button
                                className="copybutton"
                                style={{ marginRight: "15px", gap: "5px" }}
                                onClick={() => handleEditPractice(topic)}
                              >
                                {/* <img
                                  src={images.editpage}
                                  alt="copy-icon"
                                  className="das-copy"
                                /> */}
                                <i
                                  class="bi bi-pencil-square"
                                  style={{ height: "26px", width: "20px" }}
                                ></i>
                                EDIT
                              </button>

                              <button
                                className="btn btn-danger delete-btn-dashboard"
                                onClick={() => deleteClick(topic.identifier)}
                              >
                                <img
                                  src={images.deletDash}
                                  alt="trash-icon"
                                  className="das-delet"
                                />
                                Delete
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-danger delete-btn-dashboard"
                                onClick={() => deleteClick(topic.identifier)}
                              >
                                <img
                                  src={images.deletDash}
                                  alt="trash-icon"
                                  className="das-delet"
                                />
                                Delete
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div style={{ border: "1px solid #FFFFFF33" }}></div>
                      <div className="card-body">
                        <ul className="record-detail">
                          <li>
                            <h5 style={{ color: "#E19133" }}>
                              Words Per Minute:
                            </h5>
                            <p>
                              {isAudioCompleted && audio ? audio.wpm : "---"}
                            </p>
                          </li>
                          <li>
                            <h5 style={{ color: "#845EBC" }}>Level:</h5>
                            <p>{topic.level}</p>
                          </li>
                          <li>
                            <h5 style={{ color: "#F36960" }}>Topic:</h5>
                            <p>{topic.topic}</p>
                          </li>
                          <li>
                            <h5 style={{ color: "#F36960" }}>Created On:</h5>
                            <p>{formatDate(topic.created_at)}</p>
                          </li>
                        </ul>

                        {isComplete && isAudioCompleted ? (
                          <>
                            <div className="row">
                              <div className="col-lg-6 col-xl-4">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    drillVocablryClick(topic.identifier)
                                  }
                                >
                                  <img
                                    src={images.drillvocablry}
                                    alt="drill-vocabulary"
                                  />
                                  Drill vocabulary
                                </button>
                              </div>
                              <div className="col-lg-6 col-xl-4">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    interpretClick(topic.identifier)
                                  }
                                >
                                  <img
                                    src={images.interpretedMike}
                                    alt="interpret-record"
                                  />
                                  Interpret & Record
                                </button>
                              </div>
                              <div className="col-lg-6 col-xl-4">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => evaluteClick(topic.identifier)}
                                >
                                  <img src={images.evalute} alt="evaluate" />
                                  Evaluate
                                </button>
                              </div>
                            </div>
                            <div className="row mt-2 d-flex justify-content-center">
                              <div className="col-lg-3 col-md-4 mb-4 d-flex justify-content-center">
                                <button
                                  className="sharebtn-dashboard"
                                  onClick={() => copyLink(topic.identifier)}
                                >
                                  Share Practice
                                  <img
                                    src={images.share}
                                    alt="share"
                                    style={{ marginLeft: "15px" }}
                                  />
                                </button>
                              </div>
                              {/* <div className="col-lg-3 col-md-5 mb-4 d-flex justify-content-center">
                                <button
                                  className="edit-dashboard-btn"
                                  // onClick={() => editPractice(topic)}
                                  onClick={() => handleEditPractice(topic)}
                                >
                                  Edit Practice
                                  <img
                                    src={images.editpage}
                                    alt="edit-practice"
                                    style={{ marginLeft: "15px" }}
                                  />
                                </button>
                              </div> */}
                              <div className="col-lg-3 col-md-5 mb-4 d-flex justify-content-center">
                                <button
                                  className="continuepractise"
                                  onClick={() =>
                                    openRecording(topic.identifier)
                                  }
                                >
                                  VIEW RECORDING(S)
                                  <img
                                    src={images.eye}
                                    alt="view-recording"
                                    style={{ marginLeft: "15px" }}
                                  />
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="row">
                            <div className="col-lg-6 col-xl-4">
                              <div className="status">
                                <img
                                  src={
                                    isVocabularyCompleted
                                      ? images.complete
                                      : images.incomplete
                                  }
                                  alt="status-icon"
                                />
                                <span>
                                  {isVocabularyCompleted
                                    ? "Completed"
                                    : "Incomplete"}
                                </span>
                              </div>
                              <button
                                className={`btn btn-primary ${
                                  isVocabularyCompleted
                                    ? "completed"
                                    : "incomplete"
                                }`}
                                disabled={topic.response_flag === 0}
                                onClick={() =>
                                  openFlashCartHandler(topic.identifier)
                                }
                              >
                                <img
                                  src={images.drillvocablry}
                                  alt="Drillvocabulary"
                                />
                                Vocabulary
                              </button>
                            </div>

                            <div className="col-lg-6 col-xl-4">
                              <div className="status">
                                <img
                                  src={
                                    isTranscriptCompleted
                                      ? images.complete
                                      : images.incomplete
                                  }
                                  alt="status-icon"
                                />
                                <span>
                                  {isTranscriptCompleted
                                    ? "Completed"
                                    : "Incomplete"}
                                </span>
                              </div>
                              <button
                                className={`btn btn-primary ${
                                  isTranscriptCompleted
                                    ? "completed"
                                    : "incomplete"
                                }`}
                                onClick={() => {
                                  if (!isComplete) {
                                    if (isTranscriptCompleted) {
                                      navigate(
                                        `/transcript/view/${topic.identifier}`
                                      );
                                    } else {
                                      navigate(
                                        `/transcript-generation/${topic.identifier}`
                                      );
                                    }
                                  } else {
                                    if (isTranscriptCompleted) {
                                      navigate(
                                        `/transcript/view/${topic.identifier}`
                                      );
                                    } else {
                                      navigate(
                                        `/transcript/${topic.identifier}`
                                      );
                                    }
                                  }
                                }}
                              >
                                <img
                                  src={images.interpretedMike}
                                  alt="interpretedMike"
                                />
                                Transcript
                              </button>
                            </div>

                            <div className="col-lg-6 col-xl-4">
                              <div className="status">
                                <img
                                  src={
                                    isAudioCompleted
                                      ? images.complete
                                      : images.incomplete
                                  }
                                  alt="status-icon"
                                />
                                <span>
                                  {isAudioCompleted
                                    ? "Completed"
                                    : "Incomplete"}
                                </span>
                              </div>
                              <button
                                className={`btn btn-primary ${
                                  isAudioCompleted ? "completed" : "incomplete"
                                }`}
                                disabled={
                                  topic.response_flag !== 2 &&
                                  topic.response_flag !== 3
                                }
                                onClick={() => {
                                  if (
                                    topic.response_flag === 2 ||
                                    topic.response_flag === 3
                                  ) {
                                    if (!isAudioCompleted) {
                                      navigate(
                                        `/voice-catalog/${topic.identifier}`
                                      );
                                    }
                                  }
                                }}
                              >
                                <img src={images.evalute} alt="Evaluate" />
                                Audio
                              </button>
                            </div>
                          </div>
                        )}

                        {!isAudioCompleted && (
                          <div className="row justify-content-center">
                            <div className="col-lg-6 col-xl-4 text-center m-2">
                              <button
                                className="continuepractise"
                                onClick={() => continueYourPractice(topic)}
                              >
                                Continue Your Practice
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    // </div>
                  );
                })
              )}
            </div>
          </div>
          <div
            id="SharedPractices"
            className={`tab-content ${toggle ? "active" : ""}`}
          >
            {loading ? (
              // <div className="loader"></div>
              <Loader />
            ) : sharedTopics?.length === 0 ? (
              <div className="tab-card">
                <p>You haven't received any shared practices yet.</p>
              </div>
            ) : error ? (
              <p className="text-danger">{error}</p>
            ) : (
              sharedTopics.map((topic) => {
                const isAudioCompleted = topic.response_flag === 3;
                const isComplete = topic.is_complete === true;
                const audio = topic.save_audio;
                return (
                  <div
                    key={topic.identifier || topic.practice_name}
                    className="card"
                  >
                    <div className="dashboard-card-header">
                      <div className="dash-card">
                        <h3>{topic.practice_name} </h3>
                        {isComplete ? (
                          ""
                        ) : (
                          <span className="dash-card-status">Incomplete</span>
                        )}
                      </div>
                    </div>
                    <div style={{ border: "1px solid #FFFFFF33" }}></div>
                    <div className="card-body">
                      <ul className="record-detail">
                        <li>
                          <h5 style={{ color: "#E19133" }}>
                            Words Per Minute:
                          </h5>
                          <p>{isAudioCompleted && audio ? audio.wpm : "---"}</p>
                        </li>
                        <li>
                          <h5 style={{ color: "#845EBC" }}>Level:</h5>
                          <p>{topic.level}</p>
                        </li>
                        <li>
                          <h5 style={{ color: "#F36960" }}>Topic:</h5>
                          <p>{topic.topic}</p>
                        </li>
                        <li>
                          <h5 style={{ color: "#F36960" }}>Created On:</h5>
                          <p>{formatDate(topic.created_at)}</p>
                        </li>
                      </ul>

                      {isComplete && isAudioCompleted ? (
                        <>
                          <div className="row">
                            <div className="col-lg-6 col-xl-4">
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  sharedDrillVocablryClick(topic.identifier)
                                }
                              >
                                <img
                                  src={images.drillvocablry}
                                  alt="drill-vocabulary"
                                />
                                Drill vocabulary
                              </button>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  sharedInterpretClick(topic.identifier)
                                }
                              >
                                <img
                                  src={images.interpretedMike}
                                  alt="interpret-record"
                                />
                                Interpret & Record
                              </button>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  sharedEvaluteClick(topic.identifier)
                                }
                              >
                                <img src={images.evalute} alt="evaluate" />
                                Evaluate
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      <Footer />
      <ConfirmDeleteModal
        showModal={showModal}
        onClose={onClose}
        onDelete={onDelete}
        message={"Are you sure you want to delete this practice?"}
      />
      <ConfirmCopyModal
        showModal={showCopyModal}
        onClose={() => setShowCopyModal(false)}
        onCopy={onCopy}
        message={"Are you sure you want to copy this practice?"}
      />
      {isShareModalOpen && (
        <ShareModal
          onClose={handleShareCloseModal}
          url={url}
          onCopy={() => copyToClipboard(url)}
        />
      )}
      {isEditModalOpen && (
        <EditModal onConfirm={handleConfirmEdit} onCancel={handleCancelEdit} />
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          show={showSubscriptionModal}
          onHide={() => setShowSubscriptionModal(false)}
        />
      )}

      {showStorageAndPracticeLimitModal && (
        <StorageAndPracticeLimit
          show={showStorageAndPracticeLimitModal}
          handleClose={() => setShowStorageAndPracticeLimitModal(false)}
        />
      )}
    </div>
  );
};

export default PractiseDashboard;
