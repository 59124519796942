import React, { useContext, useEffect, useState } from "react";
import HeaderComponent from "../../../components/Header/Header";
import images from "../../../images";
import { useNavigate, useParams } from "react-router";
import { REACT_APP_URL } from "../../../constants";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import ShareModal from "../../../components/Modal/ShareModal/ShareModal";
import { UserContext } from "../../../providers/UserContext";

const Congratulation = () => {
  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { id } = useParams();
  // console.log("Identifier:", identifier);
  const continueClick = () => {
    navigate(`/transcript-generation/${id}`);
  };
  const handleShareOpenModal = () => {
    setIsShareModalOpen(true);
  };

  const handleShareCloseModal = () => {
    setIsShareModalOpen(false);
  };
  const openFlashCartHandler = () => {
    // const params = new URLSearchParams(location.search);
    // const identifier = params.get("identifier");
    // navigate(`/game-question-and-flash-card/${id}`);
    const url = `/game-question-and-flash-card/${id}`;
    window.open(url, "_blank"); // Opens the route in a new tab
  };

  const copyLink = () => {
    handleShareOpenModal();
    const link = `${REACT_APP_URL}/game-question-and-flash-card/${id}`;
    // const link = `${REACT_APP_URL}/share-link/${id}`;

    // copyToClipboard(link);
    setUrl(link);
  };

  useEffect(() => {
    const updateUser = () => {
      const updated = {
        ...currentUser,
        totalPractice: currentUser.totalPractice ?? 0,
      };
      updateCurrentUser(updated);
    };
    if (currentUser) {
      updateUser();
    }
  }, [id]);

  const copyToClipboard = (text) => {
    console.log("Text to copy:", text);
    console.log("navigator.clipboard:", navigator.clipboard);

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard successfully");
          toast.success("Share link successfully copied");
          handleCopySuccess();
        })
        .catch((err) => {
          console.error("Failed to copy using Clipboard API:", err);
          fallbackCopyToClipboard(text);
        });
    } else {
      console.error("Clipboard API not supported");
      fallbackCopyToClipboard(text);
    }
  };

  const fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to the bottom
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      console.log("Fallback: Copying text command was ");
      handleCopySuccess(successful);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const handleCopySuccess = (successful = true) => {
    setTimeout(() => {}, 2000); // Adjust the time as needed
  };

  return (
    <div className="min-screen Congratulation">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content text-center">
          <img src={images.iconlabel} alt="icon-label" className="Icon_Label" />
          <h3 className="title">Congratulations</h3>
          <p className="des">
            Your flashcards have been successfully created. Let's generate your
            transcript now.
          </p>
          <div className="btn-list">
            {/* <button
              className="btn btn-light flashcardbtn"
              onClick={openFlashCartHandler}
            >
              VIEW FLASHCARDS NOW
              <img src={images.eye} alt="eye" />
            </button> */}
            {/* <button className="btn btn-light sharebtn" onClick={copyLink}>
              SHARE
              <img src={images.share} alt="share" />
            </button> */}
            <button
              className="btn btn-light genearation green-btn"
              onClick={continueClick}
            >
              CONTINUE WITH PRACTICE GENERATION
            </button>
          </div>
        </div>
      </div>
      <Footer />
      {isShareModalOpen && (
        <ShareModal
          onClose={handleShareCloseModal}
          url={url}
          onCopy={() => copyToClipboard(url)}
        />
      )}
    </div>
  );
};

export default Congratulation;
