import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { generateVocabulary } from "services/vocab.service";
import { REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE } from "constants";
import { FormContext } from "../../../providers/FormContext";
import { UserContext } from "../../../providers/UserContext";
import { setVocabList } from "../../../redux/vocabSlice";
import OpsModal from "../../../components/Modal/OppsModal/OpsModal";
import TestNameModal from "../../../components/Modal/Practise_test_modal/TestNameModal";
import RegenerateTranscript from "../../../components/Modal/RegenerateTranscript/RegenerateTranscript";
import ConfirmDeleteModal from "../../../components/Modal/DeleteModal/VocabDeletModal";
import Footer from "../../../components/Footer/Footer";
import ScrollDown from "../../../components/ScrollDown/ScrollDown";
import { VocabularyCard } from "../../../components/VocabularyCard/VocabularyCard";
import Loader from "../../../components/Loader/Loader";
import HeaderComponent from "../../../components/Header/Header";
import images from "../../../images";
import VocabListModal from "../../../components/VocablaryList/VocablaryList";
import {
  saveVocabPractice,
  singleRegenerateVocabulary,
} from "../../../services/vocab.service";

const EditVocabList = () => {
  const vocabList = useSelector((state) => state.vocab.vocabList);
  const { formData } = useContext(FormContext);
  const { currentUser } = useContext(UserContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cardRefs = useRef([]);

  const [displayedList, setDisplayedList] = useState(0);
  const [focusedKeywordIndex, setFocusedKeywordIndex] = useState(null);
  const [highlightedCardIndex, setHighlightedCardIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalRegenerationCount, setTotalRegenerationCount] = useState(0);
  const [allKeywords, setAllKeywords] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayedKeywords, setDisplayedKeywords] = useState([]);
  const [showCompleteVocabularyModal, setShowCompleteVocabularyModal] =
    useState(false);
  const maxRegenerations = 2;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [modals, setModals] = useState({
    delete: false,
    regenerate: false,
    regenerateFull: false,
    testName: false,
    error: false,
  });

  useEffect(() => {
    if (!formData || Object.keys(formData).length === 0) {
      navigate("/practice-dashboard");
    } else {
      setAllKeywords(vocabList);
    }
  }, [formData, vocabList, navigate]);

  useEffect(() => {
    if (location.state?.index !== undefined) {
      setFocusedKeywordIndex(location.state.index);
    }
  }, [location.state]);

  // Update displayed keywords when vocabList or displayedList changes
  useEffect(() => {
    //  console.log("displayedList", displayedList);
    //  console.log("allKeywords", allKeywords);
    if (allKeywords.length > 0 && displayedList < allKeywords.length) {
      setDisplayedKeywords(allKeywords[displayedList]?.keyword || []);
    }
  }, [allKeywords, displayedList]);

  useEffect(() => {
    if (focusedKeywordIndex !== null) {
      cardRefs.current[focusedKeywordIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setHighlightedCardIndex(focusedKeywordIndex);
    }
  }, [focusedKeywordIndex]);

  //   const handleSaveClick = () => {
  //     dispatch(setVocabList(vocabList));
  //     toast.success("Vocabulary Edited Successfully");
  //     resetFocus();
  //   };

  const handleKeywordSaveClick = (index, updatedItem) => {
    // Clone the entire allKeywords array to avoid direct mutation
    const updatedAllKeywords = [...allKeywords];

    // Find the correct outer item by displayedList index
    const updatedOuterItem = { ...updatedAllKeywords[displayedList] };

    // Clone the keyword array within the outer item
    const updatedKeywords = [...updatedOuterItem.keyword];
    // Merge the old values with the updated item at the specified index
    updatedKeywords[index] = {
      ...updatedKeywords[index], // Retain old values
      ...updatedItem, // Override with new values
    };
    setDisplayedKeywords(updatedKeywords);
    // Replace the old keyword object with the updated one in the cloned keyword arra

    // Update the keyword array inside the cloned outer item
    updatedOuterItem.keyword = updatedKeywords;
    // Replace the updated outer item in the allKeywords array
    updatedAllKeywords[displayedList] = updatedOuterItem;
    // Update the state with the new allKeywords array
    console.log("updatedAllKeywords", updatedAllKeywords);
    setAllKeywords(updatedAllKeywords);
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
    toast.success("Vocabulary updated successfully!");
  };

  const handleDeleteClick = (index) => {
    setFocusedKeywordIndex(index);
    toggleModal("delete", true);
  };

  const handleConfirmDelete = () => {
    if (focusedKeywordIndex === null) return;

    // Filter out the deleted keyword from the displayed list
    const updatedKeywords = displayedKeywords.filter(
      (_, idx) => idx !== focusedKeywordIndex
    );

    // Update the displayedKeywords state
    setDisplayedKeywords(updatedKeywords);

    // Clone allKeywords to avoid direct mutation
    const updatedAllKeywords = [...allKeywords];

    // Update the keyword list inside the relevant outer item
    updatedAllKeywords[displayedList] = {
      ...updatedAllKeywords[displayedList],
      keyword: updatedKeywords,
    };

    // Update the allKeywords state
    setAllKeywords(updatedAllKeywords);

    resetFocus();
    toast.success("Vocabulary Deleted Successfully");
  };

  const regenerateVocabulary = async () => {
    if (totalRegenerationCount >= maxRegenerations) {
      toggleModal("error", true);
      return;
    }

    setLoading(true);
    try {
      const response = await generateVocabulary(
        currentUser.access,
        formData,
        formData.region,
        [],
        REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE
      );
      const newKeywords = {
        prev: true,
        keyword: response.keywords.keywords.lists[0],
        next: false,
      };

      setAllKeywords((prev) => {
        const updatedPrev = prev.map((item, index) =>
          index === prev.length - 1 ? { ...item, next: true } : item
        );
        return [...updatedPrev, newKeywords];
      });

      toggleModal("regenerateFull", false);
      setDisplayedList(displayedList + 1);
      setTotalRegenerationCount((prev) => prev + 1);
      toast.success("Vocabulary Regenerated Successfully");
    } catch {
      toast.error("Error in regenerating vocabulary.");
    } finally {
      setLoading(false);
      toggleModal("regenerate", false);
    }
  };

  const regenerateOneVocabulary = async (feedbacks) => {
    setLoading(true);
    if (totalRegenerationCount >= maxRegenerations) {
      // toast.error("No keywords available for regeneration.");
      toggleModal("error", false);
      setLoading(false);
      return;
    }
    const excludeKeywords = displayedKeywords.map(
      (item) => item.source_language
    );

    try {
      const response = await singleRegenerateVocabulary(
        currentUser.access,
        formData,
        formData.region,
        excludeKeywords,
        feedbacks,
        REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE
      );
      console.log("API Response:", response);
      console.log(excludeKeywords, "excludeKeywords");
      if (
        response &&
        response.keywords &&
        response.keywords.keywords &&
        response.keywords.keywords.lists &&
        response.keywords.keywords.lists.length > 0 &&
        response.keywords.keywords.lists[0].length > 0
      ) {
        const newKeyword = response.keywords.keywords.lists[0][0]; // New keyword to be added
        // const updatedKeywords = [...keywords.keyword]; // Copy existing keywords

        // Transform the data to remove "definition" if it's empty
        const updatedKeywords = [...displayedKeywords].map(
          ({ prev, next, ...rest }) => ({ ...rest })
        );

        // Update the specific keyword at the given index with `prev: true, next: false`
        updatedKeywords[selectedIndex] = {
          ...newKeyword,
          prev: true,
          next: false,
        };

        // Prepare the new `newKeywords` object
        const newKeywords = {
          prev: false,
          keyword: updatedKeywords,
          next: false,
        };

        setAllKeywords((prev) => {
          // Update the last keyword's `next` value to `true` if the array is not empty
          const updatedPrev = prev.map((item, idx) => {
            if (idx === displayedList) {
              // Ensure item.keyword is updated correctly
              const updatedKeywords = item.keyword.map((k, x) =>
                x === selectedIndex
                  ? { ...k, next: true } // Set `next` to `true` for the specified index
                  : k
              );
              return { ...item, keyword: updatedKeywords }; // Return updated item
            }
            return item; // Return unmodified item for other indices
          });

          // Add the new keyword object to the array
          return [...updatedPrev, newKeywords];
        });

        setDisplayedList(displayedList + 1);
        toggleModal("regenerate", false);
        setLoading(false);
        setSelectedIndex(null);
        toast.success("Keyword Regenerated Successfully");
        setTotalRegenerationCount((prevCount) => {
          const newCount = prevCount + 1;

          return newCount; // Return the new count
        });
      } else {
        toast.error("No keyword received from API");
      }
    } catch (error) {
      console.error("Error regenerating keyword:", error);
      toast.error("Failed to regenerate keyword. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = (modal, state) => {
    setModals((prev) => ({ ...prev, [modal]: state }));
  };

  const resetFocus = () => {
    setFocusedKeywordIndex(null);
    setHighlightedCardIndex(null);
    toggleModal("delete", false);
  };

  const showRegenerateOneVocabulary = (index) => {
    if (totalRegenerationCount >= maxRegenerations) {
      toggleModal("error", true);
    } else {
      setSelectedIndex(index); // Store the index to be used in the modal
      toggleModal("regenerate", true);
    }
  };

  const handlePrivAndNextHistory = (index, direction) => {
    console.log("index", index);
    console.log("displayedList", displayedList);
    if (direction === "next") {
      setDisplayedList(displayedList + 1);
    } else {
      setDisplayedList(displayedList > 0 ? displayedList - 1 : 0);
    }
  };

  const handleSaveClick = async (testName) => {
    // const accessToken = localStorage.getItem("accessToken");

    setLoading(true);
    // const Userid = localStorage.getItem("email");

    const practiceInfo = {
      topic: formData.topic,
      source_language: formData.sourceLanguage,
      target_language: formData.targetLanguage,
      level: formData.level,
      region: formData.region,
      num_keywords: parseInt(formData.vocabularyCount, 10),
      num_lists: REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE,
      user_id: currentUser.email,
      practice_name: testName,
    };
    // const keywords = vocabList[displayedList];
    const dataToSubmit = {
      practice_info: practiceInfo,
      keywords: displayedKeywords,
      topic_additional_information: formData.topicAdditionInformation,
    };

    try {
      const result = await saveVocabPractice(dataToSubmit, currentUser.access);
      localStorage.removeItem("vocabState");
      toast.success("Vocabulary Saved Successfully");
      navigate(`/congratulation/${result.practice_info.identifier}`);
    } catch (error) {
      if (error.message.includes("already in use")) {
        // setErrorMessage(
        //   "The practice name you entered is already in use. Please choose a different practice name."
        // );
        toast.error(
          "The practice name you entered is already in use. Please choose a different practice name."
        );
        toggleModal("testName", false);
      } else {
        console.error("Error:", error);
        toast.error(
          "Oops! Something went wrong. Please try again in a moment."
        );
        // setErrorMessage(
        //   "An unexpected error occurred. Please try again later."
        // );
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to view complete vocabulary
  const handleViewCompleteVocabulary = () => {
    setShowCompleteVocabularyModal(true);
  };

  return (
    <div className="min-screen edit-list">
      {loading && <Loader />}
      <HeaderComponent />
      <div className="mainContentBox">
        {!isMobile && <ScrollDown />}
        <div className="container p-0">
          <div className="edit-contnet">
            <h1 className="title">Edit Vocabulary List</h1>
            <div className="scrollbar">
              <div className="viewBox vocabularyManageButton">
                <div className="regenrate-all-vocab-btn">
                  {displayedKeywords && allKeywords[displayedList]?.prev && (
                    <button
                      className="btn btn-secondary roundbtn"
                      onClick={() =>
                        handlePrivAndNextHistory(displayedList, "previous")
                      }
                    >
                      <img src={images.ArrowLeft} alt="ArrowLeft" />
                      <span className="tooltip">Previous Vocabulary</span>
                    </button>
                  )}
                  <button
                    className="submit mb-2"
                    onClick={() => {
                      if (totalRegenerationCount >= maxRegenerations) {
                        // Open OpsModal if max regeneration count reached
                        toggleModal("error", true);
                      } else {
                        // Open RegenerateTranscript modal
                        toggleModal("regenerateFull", true);
                      }
                    }}
                  >
                    Regenerate Full List
                  </button>
                  {displayedKeywords && allKeywords[displayedList]?.next && (
                    <button
                      className="btn btn-secondary roundbtn"
                      onClick={() =>
                        handlePrivAndNextHistory(displayedList, "next")
                      }
                    >
                      <span className="tooltip">Next Vocabulary</span>
                      <img src={images.ArrowRight} alt="ArrowRight" />
                    </button>
                  )}
                </div>

                <button className="lightVocab">
                  <Link
                    className="noDecoration"
                    onClick={handleViewCompleteVocabulary}
                  >
                    View Full Screen Mode
                  </Link>
                </button>

                <button
                  className="submit mb-2"
                  onClick={() => toggleModal("testName", true)}
                >
                  Submit
                </button>
              </div>
              {displayedKeywords.map((item, index) => (
                <VocabularyCard
                  key={item.source_language}
                  index={index}
                  item={item}
                  cardRefs={cardRefs}
                  focusedKeywordIndex={focusedKeywordIndex}
                  highlightedCardIndex={highlightedCardIndex}
                  handleKeywordSaveClick={handleKeywordSaveClick}
                  handleDeleteClick={handleDeleteClick}
                  handleRegenerateOneClick={showRegenerateOneVocabulary}
                  handlePrivAndNextHistory={handlePrivAndNextHistory}
                  setFocusedKeywordIndex={(e) => setFocusedKeywordIndex(e)}
                />
              ))}
            </div>
            <div className="btn-submit-list">
              <button
                className="submit"
                onClick={() => toggleModal("testName", true)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {showCompleteVocabularyModal && (
        <VocabListModal
          vocabList={displayedKeywords}
          url={`/edit-vocab-list`}
          onClose={() => setShowCompleteVocabularyModal(false)}
        />
      )}

      <ConfirmDeleteModal
        showModal={modals.delete}
        onClose={resetFocus}
        onDelete={handleConfirmDelete}
        message="Are you sure you want to delete this keyword?"
      />
      <RegenerateTranscript
        show={modals.regenerate}
        type="Vocabulary"
        onClose={() => toggleModal("regenerate", false)}
        onSubmit={regenerateOneVocabulary}
      />

      <RegenerateTranscript
        show={modals.regenerateFull}
        type="Vocabulary"
        onClose={() => toggleModal("regenerateFull", false)}
        onSubmit={regenerateVocabulary}
      />
      <TestNameModal
        show={modals.testName}
        name={""}
        onClose={() => toggleModal("testName", false)}
        onSubmit={handleSaveClick}
      />
      <OpsModal
        show={modals.error}
        onClose={() => toggleModal("error", false)}
      />
    </div>
  );
};

export default EditVocabList;
