import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderComponent from "components/Header/Header";
import images from "../../../images";
import { useNavigate } from "react-router";
import Footer from "../../../components/Footer/Footer";
import { UserContext } from "../../../providers/UserContext";
import SubscriptionModal from "../../../components/Modal/Subscription/Subscription";

const GeneratedVocablary = () => {
  const { currentUser } = useContext(UserContext);

  const vocabularyList = useSelector((state) => state.vocab.vocabList);
  const navigate = useNavigate();
  const [displayedList, setDisplayedList] = useState(0);


  const EditClick = (index) => {
    console.log("Edit Clicked Index:", index); // Check if index is correct here
    if (index !== undefined && index !== null) {
      navigate("/edit-vocab-list", { state: { index } });
    } else {
      console.error("Invalid Index passed to EditClick:", index);
    }
  };
  console.log("Vocabulary List:", vocabularyList);
  const handleCloseClick = () => {
    navigate("/edit-vocab-list");
  };
  useEffect(() => {
    if(currentUser && !currentUser.subscription_status){
      navigate("/practice-dashboard");
    }
  }, [currentUser])
  

  return (
    <div className="min-screen generated_List">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="container">
          <h1 className="title">Generated Vocabulary List</h1>
          <div className="card">
            <div className="card-header">
              <p className="card-title">Generated Keywords</p>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  {vocabularyList[displayedList] ? (
                    vocabularyList[displayedList].map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}.</td>
                        <td>{item.source_language}</td>
                        <td>{item.target_language}</td>
                        <td>
                          <img
                            className="edit-image"
                            src={images.edit}
                            alt="edit-icon"
                            onClick={() => EditClick(index)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No vocabulary generated.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="btn-list justify-content-center">
            <div style={{ width: "150px" }}>
              <button
                style={{ borderRadius: "100px" }}
                className="light"
                onClick={handleCloseClick}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      
    </div>
  );
};

export default GeneratedVocablary;
