import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./GeneratePracticeButton.css";
import { UserContext } from "../../providers/UserContext";
import { PlanContext } from "../../providers/PlanContext";
import { FormContext } from "../../providers/FormContext";
import images from "../../images";

const GeneratePracticeButton = ({
  isListItem,
  setLanguageDefaultValue,
  setShowStorageAndPracticeLimitModal,
  setShowSubscriptionModal,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const { remainingStorage } = useContext(PlanContext);
  const { resetFormData } = useContext(FormContext);
  // const [
  //   showStorageAndPracticeLimitModal,
  //   setShowStorageAndPracticeLimitModal,
  // ] = useState(false);
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [totalpractise, setTotalpractise] = useState();
  const [totalstorage, setTotalstorage] = useState();

  useEffect(() => {
    setTotalpractise(remainingStorage.totalPractice);
    setTotalstorage(remainingStorage.totalStorage);
  }, [remainingStorage]);

  const handleClick = () => {
    if (currentUser.subscription_status) {
      resetFormData();
      setLanguageDefaultValue();
      if (totalpractise > 0 && totalstorage > 0) {
        if (
          totalpractise <
            process.env.REACT_APP_SHOW_NOTIFICATION_PRACTICE_LIMIT ||
          totalstorage < process.env.REACT_APP_SHOW_NOTIFICATION_STORAGE_LIMIT
        ) {
          toast.info(
            <div>
              You are approaching your storage limit. Delete any of your{" "}
              <a
                href="/purchase-plan"
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: "3px",
                }}
              >
                practices {""}
              </a>
              or recordings to make more space or purchase additional {""}
              <a
                href="/purchase-storage"
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: "3px",
                }}
              >
                storage {""}
              </a>
              for a one time fee of $9.99.
            </div>,
            {
              className: "custom-toast",
              bodyClassName: "custom-toast-body",
              position: "top-center",
            }
          );
        }
        navigate("/vocabulary-screen");
      } else {
        setShowStorageAndPracticeLimitModal(true);
      }
    } else {
      setShowSubscriptionModal(true);
    }
  };

  return (
    // topics.length > 0 && (
    <>
      {isListItem ? (
        <span onClick={handleClick} className="generate-practice-list-item">
          Create a Practice
        </span>
      ) : (
        <button
          onClick={handleClick}
          className={`btn btn-success`}
          style={{ textTransform: "none" }}
        >
          Generate a Practice
          <img
            src={images.pluscircle}
            className="plus-circle-icon"
            alt="plus-circle-icon"
          />
        </button>
      )}
    </>
    // )
  );
};

export default GeneratePracticeButton;
