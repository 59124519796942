// import React, { useState, useEffect } from "react";
// import "./Research.css";
// const Research = ({ keywords, userResponses, setUserResponses, onSubmit }) => {
//   const [data, setData] = useState(keywords);
//   const handleIconClick = (item, response) => {
//     setUserResponses((prevResponses) => ({
//       ...prevResponses,
//       [item.source_language]: response,
//     }));
//   };
//   const calculateScore = () => {
//     let score = 0;
//     data.forEach((item) => {
//       const userResponse = userResponses[item.source_language];
//       if (userResponse === "right" && item.target_language) {
//         score++;
//       }
//     });
//     return score;
//   };
//   const handleAnswer = (item, answer) => {
//     const response = answer === "right" ? "right" : "wrong";
//     handleIconClick(item, response);
//   };
//   useEffect(() => {
//     console.log("userResponses:", userResponses);
//   }, [userResponses]);
//   const totalScore = calculateScore();
//   const totalPercentage = ((totalScore / data.length) * 100).toFixed(2);
//   const handleSubmit = () => {
//     onSubmit();
//   };
//   return (
//     <div className="research_container">
//       <h2>Scoring Units to Research</h2>
//       <p>
//         Look up the vocabulary units below in a reputable dictionary or
//         glossary.
//       </p>
//       <table className="scoring-table">
//         <thead>
//           <tr>
//             <th>Source Language</th>
//             <th>Target Language ({data.length} Scoring Units)</th>
//             <th>Answers</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((item, index) => {
//             const userResponse = userResponses[item.source_language];
//             return (
//               <tr key={index}>
//                 <td>{item.source_language}</td>
//                 <td>{item.target_language}</td>
//                 <td>
//                   <span
//                     className={`right ${
//                       userResponse === "right" ? "selected" : "disabled"
//                     }`}
//                     onClick={() => handleAnswer(item, "right")}
//                   >
//                     {userResponse === "right" ? "✔" : ""}
//                   </span>
//                   <span
//                     className={`wrong ${
//                       userResponse === "wrong" ? "selected" : "disabled"
//                     }`}
//                     onClick={() => handleAnswer(item, "wrong")}
//                   >
//                     {userResponse === "wrong" ? "❌" : ""}
//                   </span>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//       <div className="total-score">
//         <span>
//           Total <br></br>Score :&nbsp;&nbsp;
//           {/* {totalScore} / {data.length} */}
//           {totalPercentage}%
//         </span>
//       </div>
//       <button className="submit-btn" onClick={handleSubmit}>
//         Back
//       </button>
//     </div>
//   );
// };
// export default Research;
import React, { useState, useEffect } from "react";
import "./Research.css";
import { Navigate, useNavigate } from "react-router";

const Research = ({
  keywords,
  userResponses,
  setUserResponses,
  onSubmit,
  RecordAgain,
}) => {
  const [data] = useState(keywords);
  const navigate = useNavigate();
  const handleIconClick = (item, response) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [item.source_language]: response,
    }));
  };

  const calculateScore = () => {
    let rightCount = 0;
    let wrongCount = 0;
    let researchCount = 0;

    data.forEach((item) => {
      const userResponse = userResponses[item.source_language];
      if (userResponse === "right") {
        rightCount++;
      } else if (userResponse === "wrong") {
        wrongCount++;
      } else if (userResponse === "research") {
        researchCount++;
      }
    });

    const totalAnswered = rightCount + wrongCount;
    const totalPercentage = ((rightCount / data.length) * 100).toFixed(2);
    return {
      rightCount,
      wrongCount,
      researchCount,
      totalPercentage,
      totalAnswered,
    };
  };

  const handleAnswer = (item, answer) => {
    handleIconClick(item, answer);
  };

  useEffect(() => {
    console.log("userResponses:", userResponses);
  }, [userResponses]);

  const {
    rightCount,
    wrongCount,
    researchCount,
    totalPercentage,
    totalAnswered,
  } = calculateScore();

  const handleSubmit = () => {
    onSubmit();
  };
  const handleClose = () => {
    navigate("/practice-dashboard");
  };
  const handleRecordAgain = () => {
    RecordAgain();
  };
  return (
    <div className="research_container">
      <h2>Result of Scoring Units</h2>
      {/* <p>
        Look up the vocabulary units below in a reputable dictionary or
        glossary.
      </p> */}
      <div className="scoring-table-content">
        <table className="scoring-table">
          <thead>
            <tr>
              <th>Source Language</th>
              <th>Target Language ({data.length} Scoring Units)</th>
              <th>Answers</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const userResponse = userResponses[item.source_language];

              return (
                <tr key={index}>
                  <td>{item.source_language}</td>
                  <td>{item.target_language}</td>
                  <td>
                    <span
                      className={`right ${
                        userResponse === "right" ? "selected" : "disabled"
                      }`}
                      onClick={() => handleAnswer(item, "right")}
                    >
                      {userResponse === "right" ? "✔" : ""}
                    </span>
                    <span
                      className={`wrong ${
                        userResponse === "wrong" ? "selected" : "disabled"
                      }`}
                      onClick={() => handleAnswer(item, "wrong")}
                    >
                      {userResponse === "wrong" ? "❌" : ""}
                    </span>
                    {/* <span
                      className={`research ${
                        userResponse === "research" ? "selected" : "disabled"
                      }`}
                      onClick={() => handleAnswer(item, "research")}
                    >
                      {userResponse === "research" ? "🔍" : ""}
                    </span> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="total-score">
        {/* <h3>TOTAL</h3> */}

        <div className="total-score-content">
          <div className="score-value">Score: {totalPercentage}%</div>
          <div className="stats">
            <div className="count-container">
              <span className="label">Right</span>
              <span>{rightCount}</span>
            </div>

            <div className="count-container">
              <span className="label">Wrong</span>
              <span>{wrongCount}</span>
            </div>

            {/* <div className="count-container">
              <span className="label">Research</span>
              <span>{researchCount}</span>
            </div> */}
          </div>
        </div>

        {/* <div className="message">
          {totalPercentage >= 50
            ? "Congratulations, you passed! Keep up the great work."
            : "Keep practicing to improve your score!"}
        </div> */}
      </div>
      <div className="btn-box-btm text-center pt-3">
        <button className="submit-btn" onClick={handleSubmit}>
          Back
        </button>
        <button className="submit-btn" onClick={handleRecordAgain}>
          Record Again
        </button>
        <button className="submit-btn" onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Research;
