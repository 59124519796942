import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import { getUserStorage, getUserStorageAndPlan } from '../services/subscription.service';

// Create a new context
export const PlanContext = createContext();

// Provider component
export const PlanProvider = ({ children }) => {
    const [planDetails, setPlanDetails] = useState(null);
    const [usesStorage, setUsesStorage] = useState(null);
    const [remainingStorage, setRemainingStorage] = useState({
        totalStorage: 0,
        totalPractice: 0,
    });
    const [loading, setLoading] = useState(true);
    const { currentUser } = useContext(UserContext);

    // Fetch user plan details
    const fetchPlanDetails = async () => {
        setLoading(true);
        try {
            const response = await getUserStorageAndPlan(currentUser.email, currentUser.access);
            const responseStorage = await getUserStorage(currentUser.email, currentUser.access);

            setPlanDetails({
                email: response.email,
                totalStorage: response.total_storage_with_addition ?? 0,
                lastPlan: response.last_plan,
            });

            setUsesStorage({
                totalStorage: responseStorage.total_storage ?? 0,
                totalPractice: responseStorage.total_practice_names_count ?? 0,
            });
        } catch (error) {
            console.error('Error fetching user plan details:', error);
        } finally {
            setLoading(false);
        }
    };

    // Calculate remaining storage
    useEffect(() => {
        const calculateRemainingStorage = () => {
            try {
                const totalPractice = planDetails.lastPlan ? planDetails.lastPlan.practice_limit - usesStorage.totalPractice : 0;
                const totalStorage = planDetails.totalStorage - usesStorage.totalStorage;
             
                // const convertMbToGbStorage = totalStorage ? convertMbToGb(totalStorage) : 0;
                if (currentUser && currentUser.subscription_status) {
                    setRemainingStorage({
                        totalStorage: totalStorage > 0 ? totalStorage.toFixed(2) : 0,
                        totalPractice: totalPractice > 0 ? totalPractice : 0,
                    });
                   
                } else {
                    setRemainingStorage({
                         totalStorage: totalStorage > 0 ? totalStorage.toFixed(2) : 0,
                        totalPractice: 0,
                    });
                }

            } catch (error) {
                console.error('Error calculating remaining storage:', error);
            }
        };

        if (usesStorage && planDetails) {
            calculateRemainingStorage();
        }
    }, [usesStorage, planDetails]);

    const convertMbToGb = (mb) => {
        return (mb / 1024).toFixed(2); // Converts MB to GB and keeps two decimal places
    };

    // Reset function
    const resetPlanContext = () => {
        setPlanDetails(null);
        setUsesStorage(null);
        setRemainingStorage({
            totalStorage: 0,
            totalPractice: 0,
        });
        setLoading(false);
    };

    // Re-fetch details when user logs in
    useEffect(() => {
        if (currentUser.access) {
            fetchPlanDetails();
        } else {
            resetPlanContext();
        }
    }, [currentUser]);

    return (
        <PlanContext.Provider
            value={{
                planDetails,
                usesStorage,
                remainingStorage,
                loading,
                resetPlanContext,
            }}
        >
            {children}
        </PlanContext.Provider>
    );
};
