import React from "react";
import PropTypes from "prop-types";
import "./Badge.css"; // Create this CSS file for styling

const IconWithBadge = ({
  icon,
  badgeContent,
  badgePosition = "top-right",
  onClick,
}) => {
  return (
    <div className="icon-with-badge" onClick={onClick}>
      {/* <div className="icon">{icon}</div> */}
      {badgeContent && (
        <div className={`badge ${badgePosition}`}>{badgeContent}</div>
      )}
    </div>
  );
};

IconWithBadge.propTypes = {
  icon: PropTypes.node.isRequired, // Icon should be passed as a React node (e.g., from a library like FontAwesome)
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Badge content can be text or a number
  badgePosition: PropTypes.oneOf([
    "top-left",
    "top-right",
    "bottom-left",
    "bottom-right",
  ]), // Badge position options
  onClick: PropTypes.func, // Optional click handler
};

IconWithBadge.defaultProps = {
  badgeContent: null,
  badgePosition: "top-right",
  onClick: null,
};

export default IconWithBadge;
