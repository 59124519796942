import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Tooltip } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import images from "../../images";
import "./Header.css";
import { useLocation, useNavigate } from "react-router";
import { logout } from "../../services/user.service";
import { UserContext } from "../../providers/UserContext";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import SubscriptionModal from "../Modal/Subscription/Subscription";
import IconWithBadge from "../Badges/Badge";
import BarStrip from "../BarStrip/Barstrip";
import { PlanContext } from "../../providers/PlanContext";
import { toast } from "react-toastify";
import InfoModal from "../Modal/InfoModal/InfoModal";
import StorageAndPracticeLimit from "../Modal/StorageAndPracticeLimit/StorageAndPracticeLimit";
import GeneratePracticeButton from "../GeneratePractise/GeneratePracticeButton";
import { FormContext } from "../../providers/FormContext";

function HeaderComponent() {
  const [firstName, setFirstName] = useState("");
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { currentUser, resetCurrentUser } = useContext(UserContext);
  const { remainingStorage } = useContext(PlanContext);
  const [topics, setTopics] = useState([]);
  const { resetFormData, updateFormData } = useContext(FormContext);
  const { planDetails } = useContext(PlanContext);

  const [usertoken] = useState(currentUser.access || "");
  const [userrefreshToken] = useState(currentUser.access || "");
  // const [userName] = useState(currentUser.first_name || "");
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [practiseCounter, setPractiseCOunter] = useState();
  const [storageCounter, setStorageCOunter] = useState();
  const [hasBarStrip, setHasBarStrip] = useState(true);
  const [totalpractise, setTotalpractise] = useState();
  const [totalstorage, setTotalstorage] = useState();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalHeader, setInfoModalHeader] = useState(false);
  const [infoModalContent, setInfoModalContent] = useState(false);
  const [
    showStorageAndPracticeLimitModal,
    setShowStorageAndPracticeLimitModal,
  ] = useState(false);

  useEffect(() => {
    setPractiseCOunter(remainingStorage.totalPractice);
    setStorageCOunter(remainingStorage.totalStorage);
  }, [remainingStorage]);

  useEffect(() => {
    if (!accessToken) {
      localStorage.clear();
      navigate("/login");
      return;
    }
  }, [accessToken, currentUser]);

  // const logoClick = () => {
  //   navigate("/welcome");
  // };

  const DashboardClick = (e) => {
    e.stopPropagation();
    if (location.pathname === "/practice-dashboard") {
      // Reload the page without query parameters by setting the window location explicitly
      window.location.href = "/practice-dashboard";
    } else {
      // Navigate to the dashboard
      navigate("/practice-dashboard");
    }
  };
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  const TutorialsClick = () => {
    navigate("/tutorial");
  };

  useEffect(() => {
    // if (currentUser?.last_name) {
    //   setShowModal(false);
    // } else {
    // setShowModal(true);
    // }

    const storedFirstName = currentUser.first_name;
    if (storedFirstName) {
      const name =
        storedFirstName.charAt(0).toUpperCase() +
        storedFirstName.slice(1).toLowerCase();
      setFirstName(name);
    }
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentUser]);

  const handleLogoMouseEnter = () => setShowPopup(true);
  const handleLogoMouseLeave = () => setShowPopup(false);
  const handlePopupClose = () => setShowPopup(false);

  const isActiveDashboard = location.pathname === "/practice-dashboard";

  const ProfileClicked = () => {
    navigate("/profile");
  };
  const logOutClick = async () => {
    try {
      if (!usertoken) {
        resetCurrentUser();

        const isChecked = localStorage.getItem("checkbox") === "true";
        if (isChecked) {
          const email = localStorage.getItem("email");
          const password = localStorage.getItem("password");
          const checkbox = localStorage.getItem("checkbox");

          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
          localStorage.setItem("checkbox", checkbox);
        }

        // Clear sensitive authentication data (tokens)
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/login");
        return;
      }

      const data = await logout(usertoken, userrefreshToken);

      if (data.status) {
        throw new Error(`HTTP error! Status: ${data.message}`);
      }

      resetCurrentUser();

      const isChecked = localStorage.getItem("checkbox") === "true";
      if (isChecked) {
        const email = localStorage.getItem("email");
        const password = localStorage.getItem("password");
        const checkbox = localStorage.getItem("checkbox");

        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("checkbox", checkbox);
      }

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/login");
    } catch (error) {
      resetCurrentUser();
      // Ensure we clear sensitive data on error as well
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/login");
      console.error("Logout error:", error);
    }
  };

  const PlanSubscriptionClicked = () => {
    navigate("/purchase-plan");
  };

  const PlanStorageClicked = () => {
    navigate("/purchase-storage");
  };

  useEffect(() => {
    setTotalpractise(remainingStorage.totalPractice);
    setTotalstorage(remainingStorage.totalStorage);
  }, [remainingStorage]);

  // const generatePractice = () => {
  //   if (currentUser.subscription_status) {
  //     setShowPopup(false);
  //     if (totalpractise > 0 && totalstorage > 0) {
  //       if (
  //         totalpractise <
  //           process.env.REACT_APP_SHOW_NOTIFICATION_PRACTICE_LIMIT ||
  //         totalstorage < process.env.REACT_APP_SHOW_NOTIFICATION_STORAGE_LIMIT
  //       ) {
  //         toast.info(
  //           `You are nearing your usage limits. You have currently remaining ${totalpractise} practices and ${totalstorage} MB of storage. Please upgrade your plan to continue using this feature without interruptions.`,
  //           {
  //             className: "custom-toast",
  //             bodyClassName: "custom-toast-body",
  //             position: "top-center",
  //           }
  //         );
  //       }
  //       navigate("/vocabulary-screen");
  //     } else {
  //       setShowStorageAndPracticeLimitModal(true);
  //     }
  //   } else {
  //     setShowSubscriptionModal(true);
  //   }
  // };

  const commanCase = (e) => {
    e.stopPropagation();
    if (location.pathname === "/comman-use-case") {
      // Reload the page if already on dashboard
      window.location.reload();
    } else {
      // Navigate to the dashboard
      navigate("/comman-use-case");
    }
  };
  const SharedPractiseClik = () => {
    setShowPopup(false);
    navigate(`/practice-dashboard?sharedPractice=true`);
  };

  const openInfoModal = (type) => {
    setShowInfoModal(true);
    if (type === "practice") {
      setInfoModalHeader("Practice Generations");
      // eslint-disable-next-line no-lone-blocks
      {
        planDetails && planDetails.lastPlan
          ? setInfoModalContent(
              "These are the number of practice generations in your account, which reset to 25 each monthly billing cycle."
            )
          : setInfoModalContent(
              "After subscribing you will have 25 practice generations in your account, Additional practice can be purchased, as needed. "
              // "After subscribing, you will have 25 practice generations available in your account, which reset to 25 at the start of each monthly billing cycle."
            );
      }
    } else {
      setInfoModalHeader("Storage");
      // eslint-disable-next-line no-lone-blocks
      {
        planDetails && planDetails.lastPlan
          ? setInfoModalContent(
              "This is the amount of storage available in your account. You may delete any practice or recording to make more space. Additionally, you may purchase additional storage for $9.99."
            )
          : setInfoModalContent(
              "After subscribing you will have 500 MBs of storage for your lessons and recordings. Additional storage can be purchased, as needed. "
            );
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const convertMbToGb = (mb) => {
    return (mb / 1024).toFixed(2); // Converts MB to GB and keeps two decimal places
  };
  // function convertMbToGb(mb) {
  //   return (mb / 1000).toFixed(2); // Converts MB to GB using the decimal system
  // }

  return (
    <>
      {/* <div className="bar-strip-div"> */}
      {/* {!isScrolled && hasBarStrip && <BarStrip />} */}
      {/* </div> */}
      <Navbar
        bg="transparent"
        expand="lg"
        className={`custom-navbar ${isSticky ? "sticky" : ""}`}
      >
        <Navbar.Brand
          // onClick={logoClick}
          onMouseEnter={handleLogoMouseEnter}
          onMouseLeave={handleLogoMouseLeave}
        >
          {/* <img
          src={images.logoHigh}
          alt="logo"
          className="logo-img"
          onClick={logoClick}
        /> */}
          <div className="headerlogo-text-dropdown">
            <HeaderLogo />
          </div>

          {showPopup && (
            <div className="popup-menu">
              <div className="popmenu-first-div">
                <div className="logo-header">
                  <div className="header-logo">
                    <img
                      src={images.bigLogo} // Replace with your logo path
                      alt="Logo"
                      className="header-logo-img"
                    />
                  </div>
                  <div className="headerlogo-text">
                    <span className="orange">INTERPRETER</span>
                    <span className="headerlogo-text-black">PRO</span>
                  </div>
                </div>
                <button className="popup-close" onClick={handlePopupClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>
                </button>
              </div>
              <ul>
                <li onClick={DashboardClick}>Dashboard</li>
                {/* <li onClick={generatePractice}>Create a Practice</li> */}
                <li>
                  {" "}
                  <GeneratePracticeButton
                    setLanguageDefaultValue={() => {}}
                    setShowStorageAndPracticeLimitModal={
                      setShowStorageAndPracticeLimitModal
                    }
                    setShowSubscriptionModal={setShowSubscriptionModal}
                    isListItem={true}
                  />
                </li>
                {/* <li onClick={() => navigateTo("/add-credits")}>
                Add Practice Credits
              </li> */}
                <li onClick={commanCase}>See Common Use Cases</li>
                <li onClick={DashboardClick}>View Published Practices</li>
                <li onClick={SharedPractiseClik}>View Shared Practices</li>
                {/* <li onClick={() => navigateTo("/view-tutorials")}>
                View Tutorials
              </li> */}
                <li>
                  {" "}
                  <a
                    href="https://interpretrain.com/pages/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="popup-contactUS"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarSupportedContent" />
        <Navbar.Collapse id="navbarSupportedContent">
          <Nav className="ml-auto nav-links p-2">
            <Nav.Link>
              <div className="remaining-credit-section">
                {/* <span className="remaining-credit mb-1">Remaining Credit: 8</span>
              <span className="remaining-storage">Remaining Storage: 8 GB</span> */}
                <div
                  className={`${
                    planDetails && planDetails.lastPlan
                      ? "remaining-credit"
                      : "remaining-credit opacity-50"
                  }`}
                  onClick={() => openInfoModal("practice")}
                >
                  <img
                    className="credit-image"
                    title={`Remaining Practice Credit : ${practiseCounter}`}
                    src={images.Credit}
                    alt="credit-image"
                  />
                  {planDetails && planDetails.lastPlan ? (
                    <IconWithBadge
                      iconClass="bi bi-bell"
                      badgeContent={(practiseCounter ?? 0).toString()}
                      // badgeContent={`${convertMbToGb(storageCounter)} GB`}
                      badgePosition="top-right"
                      // onClick={handleClick}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`${
                    planDetails && planDetails.lastPlan
                      ? "remaining-storage"
                      : "remaining-storage"
                  }`}
                  onClick={() => openInfoModal("storage")}
                >
                  <img
                    className="credit-image"
                    src={images.Storage}
                    alt="storage-image"
                    title={`Storage Credit :${convertMbToGb(
                      storageCounter
                    )} GBs`}
                    style={{ width: "28px" }}
                  />

                  <IconWithBadge
                    iconClass="bi bi-bell"
                    badgeContent={`${Math.floor(storageCounter)} MBs`}
                    badgePosition="top-right"
                    // onClick={handleClick}
                  />
                </div>
              </div>
            </Nav.Link>
            <Nav.Link onClick={TutorialsClick}>Tutorials</Nav.Link>

            <Nav.Link
              className={`mb-1 ${isActiveDashboard ? "active" : ""}`}
              onClick={DashboardClick}
            >
              Dashboard
            </Nav.Link>
            {/* <Nav.Link className="mb-1" href="#">
            <img src={images.user} alt="user" className="user-icon" />
          </Nav.Link> */}
            <NavDropdown title={firstName} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={ProfileClicked}>
                Profile{" "}
              </NavDropdown.Item>
              {/* <NavDropdown.Item onClick={PlanCliked}>Plan</NavDropdown.Item> */}
              <NavDropdown.Item onClick={PlanSubscriptionClicked}>
                Subscription Plan
              </NavDropdown.Item>
              {/* {currentUser && currentUser.subscription_status ? (
                <NavDropdown.Item onClick={PlanStorageClicked}>
                  Storage Plan
                </NavDropdown.Item>
              ) : (
                ""
              )} */}

              <NavDropdown.Item onClick={logOutClick}>Log Out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        {showSubscriptionModal && (
          <SubscriptionModal
            show={showSubscriptionModal}
            onHide={() => setShowSubscriptionModal(false)}
          />
        )}

        {showInfoModal && (
          <InfoModal
            show={showInfoModal}
            handleClose={() => setShowInfoModal(false)}
            header={infoModalHeader}
            content={infoModalContent}
          />
        )}
        {showStorageAndPracticeLimitModal && (
          <StorageAndPracticeLimit
            show={showStorageAndPracticeLimitModal}
            handleClose={() => setShowStorageAndPracticeLimitModal(false)}
          />
        )}
      </Navbar>
    </>
  );
}

export default HeaderComponent;
