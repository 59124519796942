import React, { useContext, useEffect, useState } from "react";
import "./BillingHistory.css";
import Loader from "../../../../components/Loader/Loader";
import { UserContext } from "../../../../providers/UserContext";
import { getBillingHistory } from "../../../../services/transaction.service";
import { cancelRequestSubscription } from "../../../../services/subscription.service";
import { toast } from "react-toastify";
import ConfirmSubscriptionModal from "../../../../components/Modal/CancleSubscription/CancleSubscription";

const BillingHistory = () => {
  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const { currentUser, updateCurrentUser } = useContext(UserContext);

  // Fetch data from API
  useEffect(() => {
    const fetchBillingHistory = async () => {
      try {
        const response = await getBillingHistory(
          currentUser.email,
          currentUser.access
        ); // Replace with actual API URL

        // const data = await response.json();
        // console.log("data", response);
        setBillingData(response.billing_history || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBillingHistory();
  }, [currentUser]);

  // const cancelSubscription = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await cancelRequestSubscription(
  //       currentUser.email,
  //       currentUser.access
  //     ); // Replace with actual API URL
  //     toast.success("Your subscription has been successfully canceled.", {
  //       className: "toast-black-text",
  //     });
  //     // const data = await response.json();
  //     if (currentUser.subscription_status) {
  //       const updated = {
  //         ...currentUser,
  //         subscription_status: false,
  //       };
  //       updateCurrentUser(updated);
  //     }
  //     console.log("data", response);
  //   } catch (err) {
  //     toast.error(
  //       "Subscription cancellation failed. Please try again or contact support.",
  //       {
  //         className: "toast-black-text",
  //       }
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const cancelSubscription = async () => {
    setLoading(true);
    try {
      const response = await cancelRequestSubscription(
        currentUser.email,
        currentUser.access
      );
      toast.success("Your subscription has been successfully canceled.", {
        className: "toast-black-text",
      });
      if (currentUser.subscription_status) {
        const updated = {
          ...currentUser,
          subscription_status: false,
        };
        updateCurrentUser(updated);
      }
      console.log("data", response);
    } catch (err) {
      toast.error(
        "Subscription cancellation failed. Please try again or contact support.",
        {
          className: "toast-black-text",
        }
      );
    } finally {
      setLoading(false);
      setShowModal(false); // Close the modal after processing
    }
  };
  return (
    <div className="billing-history">
      <div className="profile-info-header-content">
        <h3>Billing History</h3>
        {currentUser && currentUser.subscription_status ? (
          <button
            className="profile-edit-button"
            onClick={() => setShowModal(true)}
          >
            Cancel Subscription
          </button>
        ) : (
          ""
        )}
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <p style={{ color: "red" }}>Error: {error}</p>
      ) : billingData.length === 0 ? (
        <p style={{ padding: "3px 10px" }}>No billing history found.</p>
      ) : (
        <table className="billing-history-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Plan</th>
            </tr>
          </thead>
          <tbody>
            {billingData.map((item) => (
              <tr key={item.transaction_id}>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>
                  {item.price} {item.currency.toUpperCase()}
                </td>
                <td>{item.status}</td>
                {/* <td>{item.active_plan}</td> */}
                <td>
                  {item.active_plan ? item.active_plan : item.inactive_plan}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmSubscriptionModal
        showModal={showModal}
        onClose={() => setShowModal(false)} // Close the modal
        onDelete={cancelSubscription} // Call cancellation logic
        message="Are you sure you want to cancel your subscription? Canceling will delete all your published practices and audio recordings, and all shared practices will become inactive."
      />
    </div>
  );
};

export default BillingHistory;
