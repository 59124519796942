import axios from "axios";
import { REACT_APP_GET_TRANSACTION_DETAILS, REACT_APP_GET_TRANSACTION_HISTORY } from "../constants";

/**
 * Fetch transaction details from the backend.
 * @param {string} paymentId - The ID of the payment to fetch details for.
 * @returns {Promise<Object>} - The transaction details.
 */
export const getTransactionDetails = async (paymentId, token) => {

    if (!token) {
        throw new Error("No access token found.");
    }

    try {
        // const response = await axios.get(`${REACT_APP_GET_TRANSACTION_DETAILS} ${paymentId}`);
        const response = await axios.get(
            `${REACT_APP_GET_TRANSACTION_DETAILS}${paymentId}/`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data; // Return transaction details
    } catch (error) {
        throw new Error(
            error.response?.data?.error || "Failed to fetch transaction details."
        );
    }

};



export const getBillingHistory = async (email, token) => {

    if (!token) {
        throw new Error("No access token found.");
    }

    try {
        // const response = await axios.get(`${REACT_APP_GET_TRANSACTION_DETAILS} ${paymentId}`);
        const response = await axios.post(
            REACT_APP_GET_TRANSACTION_HISTORY,
            {
                email
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data; // Return transaction details
    } catch (error) {
        throw new Error(
            error.response?.data?.error || "Failed to fetch transaction details."
        );
    }

};
