import React, { useContext } from "react";
import "./Tabs.css";
import { PlanContext } from "../../../../providers/PlanContext";

const Tabs = ({ activeTab, setActiveTab }) => {
  const { planDetails } = useContext(PlanContext);

  return (
    <div className="tabs">
      <button
        className={`tab-button ${activeTab === "profile" ? "active" : ""}`}
        onClick={() => setActiveTab("profile")}
      >
        Profile Info
      </button>
      <button
        className={`tab-button ${activeTab === "currentPlan" ? "active" : ""}`}
        onClick={() => setActiveTab("currentPlan")}
      >
        Current Plan
      </button>
      {planDetails && planDetails.lastPlan ? (
        <button
          className={`tab-button ${
            activeTab === "billingHistory" ? "active" : ""
          }`}
          onClick={() => setActiveTab("billingHistory")}
        >
          Billing History
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Tabs;
