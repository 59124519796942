import React from "react";
import "./ConfirmationModal.css"; // Assuming you want to style it separately

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    // <div className="confirmation-modal">
    //   <p>Are you sure you want to continue with this practice?</p>
    //   <button className="play-sample-button " onClick={onConfirm}>Yes</button>
    //   <button className="play-sample-button select-button mt-2" onClick={onCancel}>No</button>
    // </div>

    <>
      <div
        className={`modal fade show`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ maxWidth: "445px" }}>
            <div className="Confirm-Audioplay-close-div">
              <span
                className="Confirm-AudioPlay-close-button"
                onClick={onCancel}
              >
                <i className="bi bi-x-circle"></i>
              </span>
            </div>
            <div className="modal-body confirm-modal-body">
              <div className="heading-top" style={{ maxWidth: "340px" }}>
                <h2 style={{ fontWeight: "700px !important" }}>
                  Voice Selection
                </h2>
              </div>
              {/* <div className="delete-item" style={{ maxWidth: "340px" }}>
                <h2>Are you sure you want to continue with this audio?</h2>
              </div> */}
              {/* <div className="modal-btn-group">
                <button
                  type="button"
                  className="edit-modal-btn edit-modal-btn-no"
                  onClick={onCancel}
                >
                  No
                </button>
                <button
                  type="button"
                  className="edit-modal-btn edit-modal-btn-yes"
                  style={{ margin: "0px" }}
                  onClick={onConfirm}
                >
                  Yes
                </button>
              </div> */}
              <div className="delete-modal-btn-group-confirm">
                <button type="button" className="nobtn" onClick={onConfirm}>
                  Confirm
                </button>
                <button type="button" className="yesbtn" onClick={onCancel}>
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
