import React, { useState, createContext } from "react";

export const PracticeContext = createContext();

export const PracticeProvider = ({ children }) => {
  const ENV_PRACTICE_LIMIT = parseInt(process.env.REACT_APP_PRACTICE_LIMIT, 10);
  const ENV_STORAGE_LIMIT = parseInt(process.env.REACT_APP_STORAGE_LIMIT_MB, 10); // In MB

  const [userPracticeLimit, setUserPracticeLimit] = useState(ENV_PRACTICE_LIMIT);
  const [userStorageLimit, setUserStorageLimit] = useState(ENV_STORAGE_LIMIT);
  const [currentPracticeCount, setCurrentPracticeCount] = useState(0);
  const [currentStorageUsed, setCurrentStorageUsed] = useState(0); // In MB

  const incrementPracticeCount = (count = 1) =>
    setCurrentPracticeCount((prev) => prev + count);

  const updateStorageUsed = (sizeInMB = 0) =>
    setCurrentStorageUsed((prev) => prev + sizeInMB);

  const setPracticeCount = (count) => setCurrentPracticeCount(count);
  const setStorageUsed = (sizeInMB) => setCurrentStorageUsed(sizeInMB);

  return (
    <PracticeContext.Provider
      value={{
        userPracticeLimit,
        userStorageLimit,
        currentPracticeCount,
        currentStorageUsed,
        incrementPracticeCount,
        updateStorageUsed,
        setPracticeCount,
        setStorageUsed,
      }}
    >
      {children}
    </PracticeContext.Provider>
  );
};
