import React, { useContext, useEffect } from "react";
import HeaderComponent from "../../../components/Header/Header";
import images from "../../../images";
import { useNavigate, useParams } from "react-router";
import Footer from "../../../components/Footer/Footer";
import { UserContext } from "../../../providers/UserContext";
const VoiceCongratulation = () => {
  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const continueClick = () => {
    navigate(`/practice-dashboard`);
  };

  useEffect(() => {
    const updateUser = () => {
      const updated = {
        ...currentUser,
        totalPractice: currentUser.totalPractice ?? 0,
      };
      updateCurrentUser(updated);
    };
    if (currentUser) {
      updateUser();
    }
  }, [id]);
  return (
    <div className="min-screen VoiceCongratulation">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content text-center">
          <img
            src={images.youDidIt}
            alt="icon-label"
            className="Icon_Label_you_did_it"
          />
          <h3 className="title">Great Job!</h3>
          <p className="des">
            You have successfully completed all steps. Your practice session is
            ready to begin.
          </p>
          <div className="btn-list">
            <button
              className="btn btn-light genearation green-btn"
              onClick={continueClick}
            >
              GO TO PRACTICE
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VoiceCongratulation;
