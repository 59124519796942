import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./TranscriptComponent.css";

const TranscriptSection = ({ transcriptText }) => {
  // Create a reference for the transcript container
  const [isFullScreen, setIsFullScreen] = useState(false);
  const transcriptRef = useRef(null);

  // Function to enter fullscreen
  const enterFullScreen = () => {
    if (transcriptRef.current.requestFullscreen) {
      transcriptRef.current.requestFullscreen();
    } else if (transcriptRef.current.mozRequestFullScreen) {
      transcriptRef.current.mozRequestFullScreen(); // Firefox
    } else if (transcriptRef.current.webkitRequestFullscreen) {
      transcriptRef.current.webkitRequestFullscreen(); // Chrome, Safari and Opera
    } else if (transcriptRef.current.msRequestFullscreen) {
      transcriptRef.current.msRequestFullscreen(); // IE/Edge
    }

    setIsFullScreen(!isFullScreen);
  };

  // Function to exit fullscreen
  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen(); // Firefox
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen(); // Chrome, Safari and Opera
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); // IE/Edge
    }

    setIsFullScreen(!isFullScreen);
  };

  // Handle transcript array and formatting
  const transcriptArray = Array.isArray(transcriptText)
    ? transcriptText
    : typeof transcriptText === "string"
    ? transcriptText.split("\n").filter(Boolean)
    : [];

  const formattedTranscript = transcriptArray
    .map((paragraph) => paragraph.replace(/\\|\n|<break \/>/g, "<br><br>"))
    .join("<br>");

  return (
    <section
      className={`transcript-section-transcript ${
        isFullScreen ? "fullScreenBox" : ""
      }`}
      ref={transcriptRef}
    >
      <div className="text-right">
        {isFullScreen ? (
          <button onClick={exitFullScreen} className="fullscreen-button">
            Exit Fullscreen
          </button>
        ) : (
          <button onClick={enterFullScreen} className="fullscreen-button">
            View Fullscreen
          </button>
        )}
      </div>
      {formattedTranscript.length > 0 ? (
        <div
          className="transcript-text-div"
          dangerouslySetInnerHTML={{ __html: formattedTranscript }}
        ></div>
      ) : (
        <p className="transcript-text-div">Transcript not found</p>
      )}
    </section>
  );
};

TranscriptSection.propTypes = {
  transcriptText: PropTypes.string.isRequired,
};

export default TranscriptSection;
