import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Swiper.css";
import images from "../../images";

const SwiperComponent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container">
      <Swiper
        className="swiper"
        modules={[Autoplay, Navigation]}
        autoplay={
          isMobile
            ? {
                delay: 3000,
                disableOnInteraction: false,
              }
            : false
        }
        // pagination={{ clickable: true }}
        navigation={
          isMobile && {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }
        }
        breakpoints={{
          330: { slidesPerView: 1, spaceBetween: 20 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 3, spaceBetween: 30 },
        }}
      >
        <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider1}
                alt="slider1"
              />
              <h5 className="card-title">
                Step 1: Vocabulary Flashcards Setup
              </h5>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider2}
                alt="slider2"
              />
              <h5 className="card-title">Step 2: Transcript Creation</h5>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <div className="card">
            <div className="card-body">
              <img
                className="card-img-top"
                src={images.slider3}
                alt="slider3"
              />
              <h5 className="card-title">Step 3: Audio Practice Setup</h5>
            </div>
          </div>
        </SwiperSlide>

        {/* Conditional Next/Prev Buttons */}
        {isMobile && (
          <>
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </>
        )}
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
