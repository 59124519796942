import React, { useState } from "react";
import images from "../../../images";
import "./CancleSubscription.css";

const ConfirmSubscriptionModal = ({
  showModal,
  onClose,
  onDelete,
  message,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteClick = async () => {
    setIsLoading(true); // Show loader when the "Yes" button is clicked
    await onDelete();
    setIsLoading(false); // Hide loader after the API call is completed
  };

  return (
    <>
      {showModal && <div className="modal-backdrop-blur" />}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div
          className="delete-modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="delete-modal-content">
            <div
              className="modal-body delete-modal-body"
              style={{ padding: "48px 0px 0px" }}
            >
              <div className="delete-item">
                {/* <img src={images.deleteimg} alt="delete" /> */}
                <p>{message}</p>
              </div>
              <div
                className="delete-modal-btn-group"
                style={{ justifyContent: "space-around" }}
              >
                <button type="button" className="canclebtn" onClick={onClose}>
                  No
                </button>
                <button
                  type="button"
                  className="deletbtn"
                  onClick={handleDeleteClick}
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading ? "Canceling..." : "Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmSubscriptionModal;
