import React, { useContext, useState } from "react";
import images from "../../../images";
import { useNavigate, useParams } from "react-router-dom";
import "./ResetPassword.css";
import {
  loginUser,
  registerUser,
  ResetPasswordService,
} from "../../../services/user.service";
import { toast } from "react-toastify";
import { UserContext } from "../../../providers/UserContext";
import HeaderLogo from "../../../components/HeaderLogo/HeaderLogo";
function ResetPassword() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  // const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const { updateCurrentUser } = useContext(UserContext);

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [serverError, setServerError] = useState("");
  const [showPasswordTips, setShowPasswordTips] = useState(false); // New state
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isPasswordShowConfirm, setIsPasswordShowConfirm] = useState(false);
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;

    setPasswordError("");
    setConfirmPasswordError("");

    if (!Password) {
      setPasswordError("Password is required");
      toast.error("Password is required.", {
        className: "toast-black-text",
      });
      isValid = false;
    } else if (Password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      toast.error("Password must be at least 8 characters long.", {
        className: "toast-black-text",
      });
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(Password)) {
      setPasswordError("Password must contain one special character.");
      toast.error("Password must contain one special character.", {
        className: "toast-black-text",
      });
      isValid = false;
    }
    if (Password && ConfirmPassword !== Password) {
      setConfirmPasswordError("Passwords do not match");
      toast.error("Passwords do not match.", {
        className: "toast-black-text",
      });
      isValid = false;
    }

    return isValid;
  };

  const handleSignUp = async (e) => {
    console.log(uid, "uoid");
    e.preventDefault();
    if (!validateForm()) return;

    const userData = {
      uidb64: uid,
      token: token,
      new_password: Password,
      confirm_password: ConfirmPassword,
    };

    try {
      const response = await ResetPasswordService(userData);
      console.log(response);

      if (
        response.message === "Your password has been reset successfully." ||
        response.message ===
          "Password reset successful. This token can no longer be used."
      ) {
        toast.success("Your password has been reset successfully.", {
          className: "toast-black-text",
        });
        navigate("/login");
      } else {
        toast.error(response.message || "Password reset failed", {
          className: "toast-black-text",
        });
      }
    } catch (error) {
      setServerError("Something went wrong. Please try again later.");
      toast.error("An error occurred while resetting the password.", {
        className: "toast-black-text",
      });
    }
  };
  const handleSignIn = () => {
    navigate("/login");
  };

  return (
    <div className="min-screen screen1">
      {/* <div className="logo-style">
        <img src={images.logo} />
      </div> */}
      <div className="main-content" style={{ marginTop: "10px" }}>
        <div className="Signup">
          <form onSubmit={handleSignUp}>
            {/* <img src={images.logo} alt="logo" className="logo" /> */}
            <HeaderLogo />
            <h2>Reset Password</h2>

            <div className="inputForm mt-3">
              <img src={images.lockIcon} alt="lock" className="inputicon" />

              <input
                // type={isPasswordShow ? "text" : "password"}
                type="text"
                className="custom-input"
                placeholder="Password"
                value={Password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setShowPasswordTips(true);
                }}
                onFocus={() => setShowPasswordTips(true)} // Show suggestions when focusing on the input
                onBlur={() => setShowPasswordTips(false)} // Hide suggestions when focusing out
              />
              {/* <img
                src={isPasswordShow ? images.eyeOff : images.eye}
                alt="lock"
                className="inputicon"
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              /> */}
              {/* <i
                className={`bi ${isPasswordShow ? "bi-eye" : "bi-eye-slash"}`}
                alt="lock"
                style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
                onClick={() => setIsPasswordShow(!isPasswordShow)}
              ></i> */}
            </div>
            {passwordError && (
              <div className="forgot-error-message">{passwordError}</div>
            )}

            <div className="inputForm mt-3 mb-3">
              <img src={images.lockIcon} alt="lock" className="inputicon" />
              <input
                // type={isPasswordShowConfirm ? "text" : "password"}
                type="text"
                className="custom-input"
                placeholder="Confirm Password"
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {/* <img
                src={isPasswordShowConfirm ? images.eyeOff : images.eye}
                alt="lock"
                className="inputicon"
                onClick={() => setIsPasswordShowConfirm(!isPasswordShowConfirm)}
              /> */}
              {/* <i
                className={`bi ${
                  isPasswordShowConfirm ? "bi-eye" : "bi-eye-slash"
                }`}
                alt="lock"
                style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
                onClick={() => setIsPasswordShowConfirm(!isPasswordShowConfirm)}
              ></i> */}
            </div>
            {confirmPasswordError && (
              <div className="forgot-error-message">{confirmPasswordError}</div>
            )}
            {/* Password Suggestion Text */}
            {showPasswordTips && (
              <div className="password-suggestions mb-3">
                <p>Your password should:</p>
                <ul>
                  <li>Be at least 8 characters long</li>
                  <li>Contain at least one special character (!@#$%^&*)</li>
                  <li>Contain a mix of letters and numbers</li>
                </ul>
              </div>
            )}

            {serverError && (
              <div className="sign-error-message">{serverError}</div>
            )}
            <button type="submit" className="btn success">
              Submit
            </button>

            <p className="last-line">
              Return to
              <span
                onClick={handleSignIn}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                  marginLeft: "5px",
                }}
              >
                log in.
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
