import React from "react";
import "./StorageAndPracticeLimit.css";
import { useNavigate } from "react-router";

const StorageAndPracticeLimit = ({ show, handleClose }) => {
  const navigate = useNavigate();

  if (!show) {
    return null;
  }
  const BillingCycleClick = () => {
    navigate("/purchase-plan");
  };
  return (
    <div className="storage-practice-limit-modal-overlay">
      <div className="storage-practice-limit-modal">
        <div className="storage-practice-limit-modal-header">
          <h3>Storage and Practice Limits </h3>
          <button
            className="storage-practice-limit-modal-close-btn"
            onClick={handleClose}
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
        <div className="storage-practice-limit-modal-body">
          {/* <p>
            Your storage or practice limit has been exceeded.Please purchase a
            plan to unlock this feature.
          </p> */}
          <p>
            Your practice generation limit has been reached, and will reset at
            the beginning of your next{" "}
            <a onClick={BillingCycleClick}>Billing Cycle</a>.
          </p>
        </div>
        <div className="storage-practice-limit-modal-footer">
          <button
            className="storage-practice-limit-modal-btn"
            onClick={() => navigate("/purchase-plan")}
          >
            Buy Plan
          </button>
          <button
            className="storage-practice-limit-modal-btn"
            onClick={() => navigate("/purchase-storage")}
          >
            Buy Storage
          </button>
        </div>
      </div>
    </div>
  );
};

export default StorageAndPracticeLimit;
