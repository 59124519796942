import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/Header/Header";
import { useNavigate } from "react-router";
import "./VedioTutorial.css";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";

const VideoTutorial = () => {
  const [videoList, setVideoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  //   const goToBack = () => {
  //     navigate("/practice-dashboard");
  //   };

  //   const handleVideoClick = (videoId) => {
  //     navigate(`/tutorial/${videoId}`);
  //   };

  return (
    <>
      <div className="min-screen recordingContent">
        <HeaderComponent />
        <div className="mainContentBox recoding-list">
          {/* <h2 className="video-tutorial-header">Video Tutorials</h2> */}

          <div
            style={{
              padding: "36.5% 0 0 0",
              position: "relative",
              marginBottom: "20px",
              height: "100%",
            }}
          >
            {/* {loading && <Loader />} */}
            <iframe
              src="https://vimeo.com/showcase/11540058/embed2"
              allowFullScreen
              frameBorder="0"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: window.innerWidth <= 768 ? "100vh" : "100%", // Dynamically set height
              }}
              title="Responsive Video"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VideoTutorial;
