// import React, { useContext, useEffect, useState } from "react";
// import images from "../../../images";
// import "./PurchasePlan.css";
// import Footer from "../../../components/Footer/Footer";
// import HeaderComponent from "../../../components/Header/Header";
// import { useNavigate } from "react-router";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   getPlans,
//   saveSubscription,
// } from "../../../services/subscription.service";
// import { UserContext } from "../../../providers/UserContext";
// import { PlanContext } from "../../../providers/PlanContext";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

// const PurchasePlan = () => {
//   const { currentUser } = useContext(UserContext);
//   const navigate = useNavigate();
//   const [plans, setPlans] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { planDetails } = useContext(PlanContext);

//   //   const plans = [
//   //     {
//   //       id: 1,
//   //       name: "Basic Plan",
//   //       price: 19.00,
//   //       practice: 10,
//   //       priceId:"price_1QWu9RGbC7iSAhr2kH227DkS",
//   //       type: "subscription",
//   //       storage: "500 MB",
//   //       billingInterval: "Monthly",
//   //       details: "AI generated Vocabulary, AI generated Transcript, AI generated Audio"
//   //     },
//   // ];

//   useEffect(() => {
//     const fetchPlans = async () => {
//       try {
//         const plansData = await getPlans(currentUser.access);
//         // console.log("plansData", plansData);
//         const subscriptionPlans = plansData.filter(
//           (plan) => plan.type === "subscription"
//         );
//         // console.log("data", subscriptionPlans);
//         setPlans(subscriptionPlans);
//         setLoading(false);
//       } catch (err) {
//         console.error("Error fetching plans:", err);
//         setError("Failed to load plans. Please try again later.");
//         setLoading(false);
//       }
//     };

//     fetchPlans();
//   }, [currentUser]);

//   const handleCheckout = async (plan) => {
//     try {
//       // Call the service to save the order details
//       const sendToData = {
//         email: currentUser.email,
//         subscriptionId: plan.id,
//       };
//       const response = await saveSubscription(sendToData, currentUser.access);
//       console.log("response", response.checkout_url);
//       window.location.href = response.checkout_url;
//       // Proceed with Stripe checkout
//       // const paymentId = response.subscription_detail.identifier;
//       // const stripe = await stripePromise;
//       // await stripe.redirectToCheckout({
//       //   lineItems: [
//       //     { price: plan.priceId, quantity: 1 }, // Replace with your Price ID
//       //   ],
//       //   clientReferenceId: paymentId, // Use this to identify the payment in webhooks
//       //   mode: "payment",
//       //   successUrl: `http://localhost:3000/payment-success/${paymentId}`,
//       //   cancelUrl: `http://localhost:3000/payment-cancel/${paymentId}`,
//       //   // metadata: {
//       //   //     payment_id: paymentId, // Custom value
//       //   //   },
//       // });
//     } catch (error) {
//       console.error("Error during checkout:", error);
//       // Optionally show an error message to the user
//     }
//   };

//   return (
//     <div className="min-screen purchase-plan">
//       <HeaderComponent />

//       <div className="mainContentBox purchase-storage-main-div">
//         <div>
//           <div className="card">
//             <div className="card-body">
//               <h5 className="card-title">Choose your subscription plan</h5>
//               <div className="row">
//                 {plans.map((plan) => (
//                   <div key={plan.id} className="col-lg-4 col-md-6 m-auto">
//                     <div className="purchase-card">
//                       <p>{plan.name}</p>
//                       <h3>
//                         ${plan.price}
//                         <span>/{plan.billing_interval}</span>
//                       </h3>
//                       <ul className="puls-list">
//                         {plan.details.split(", ").map((detail, index) => (
//                           <li key={index}>
//                             <p>
//                               <img src={images.checked} alt="checked" />
//                               {detail}
//                             </p>
//                           </li>
//                         ))}
//                       </ul>
//                       <div className="practicesStorage">
//                         <p>{plan.practice_limit} Practices</p>
//                         <p>{plan.storage} Storage</p>
//                       </div>

//                       {currentUser &&
//                       currentUser.subscription_status &&
//                       planDetails &&
//                       planDetails.lastPlan &&
//                       planDetails.lastPlan.subscription_id == plan.id ? (
//                         <button className="btn btn-light disabled">
//                           Activated
//                         </button>
//                       ) : (
//                         <button
//                           className="btn btn-light"
//                           onClick={() => handleCheckout(plan)}
//                         >
//                           Subscribe now
//                         </button>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Footer */}
//       <Footer />
//     </div>
//   );
// };

// export default PurchasePlan;
import React, { useContext, useEffect, useState } from "react";
import images from "../../../images";
import "./PurchasePlan.css";
import Footer from "../../../components/Footer/Footer";
import HeaderComponent from "../../../components/Header/Header";
import { useNavigate } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import {
  getPlans,
  saveSubscription,
} from "../../../services/subscription.service";
import { UserContext } from "../../../providers/UserContext";
import { PlanContext } from "../../../providers/PlanContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const PurchasePlan = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { planDetails } = useContext(PlanContext);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansData = await getPlans(currentUser.access);
        // console.log("plansData", plansData);
        const subscriptionPlans = plansData.filter(
          (plan) => plan.type === "Subscription"
        );
        console.log("data", subscriptionPlans);
        setPlans(subscriptionPlans);
        setLoading(false);
      } catch (err) {
        // console.error("Error fetching plans:", err);
        setError("Failed to load plans. Please try again later.");
        setLoading(false);
      }
    };

    fetchPlans();
  }, [currentUser]);
  const handleCheckout = async (plan) => {
    try {
      const sendToData = {
        email: currentUser.email,
        subscriptionId: plan.id,
      };
      const response = await saveSubscription(sendToData, currentUser.access);
      window.location.href = response.checkout_url;
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div className="min-screen purchase-plan">
      <HeaderComponent />

      <div className="mainContentBox purchase-storage-main-div">
        <div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Subscription Plan</h5>
              <div className="row">
                {plans.map((plan) => {
                  let details = [];
                  let fullPracticeHosting = null;
                  console.log("Raw plan details:", plan.details);

                  try {
                    // Convert single quotes to double quotes and parse the string
                    const parsedDetails = JSON.parse(
                      plan.details.replace(/'/g, '"')
                    );

                    // Separate main details and fullPracticeHosting
                    details = parsedDetails.slice(0, -1); // Exclude the last object
                    fullPracticeHosting = {
                      text: "Full Practice Hosting",
                      items:
                        parsedDetails[parsedDetails.length - 1]
                          .fullPracticeHosting,
                    };

                    console.log("Details Array:", details);
                    console.log(
                      "Full Practice Hosting Object:",
                      fullPracticeHosting
                    );
                  } catch (error) {
                    console.error("Error parsing API response:", error.message);
                  }

                  return (
                    <div key={plan.id} className="col-lg-4 col-md-6 m-auto">
                      <div className="purchase-card">
                        {/* <p>{plan.name}</p> */}
                        <h3>
                          ${plan.price}
                          <span>/{plan.billing_interval}</span>
                        </h3>
                        <ul className="puls-list">
                          {details.length > 0 &&
                            details.map((detail, index) => (
                              <li key={index}>
                                <p>
                                  <img src={images.checked} alt="checked" />
                                  {detail}
                                </p>
                              </li>
                            ))}
                          <li>
                            {fullPracticeHosting &&
                              fullPracticeHosting.items && (
                                <div className="fullPracticeHosting">
                                  <p>
                                    <img src={images.checked} alt="checked" />
                                    Full Training Suite Including:
                                  </p>
                                  <ol className="plan-ol">
                                    {/* {fullPracticeHosting.items.map(
                                      (item, index) => (
                                        <li key={index}>{item}</li>
                                      )
                                    )} */}
                                    {fullPracticeHosting.items.map(
                                      (item, index) => (
                                        <li key={index}>
                                          <span className="list-number">
                                            {index + 1}.
                                          </span>{" "}
                                          {item}
                                        </li>
                                      )
                                    )}
                                  </ol>
                                </div>
                              )}
                          </li>
                        </ul>

                        {/* Full Practice Hosting */}

                        {currentUser &&
                        currentUser.subscription_status &&
                        planDetails &&
                        planDetails.lastPlan &&
                        planDetails.lastPlan.subscription_id == plan.id ? (
                          <button className="btn btn-light disabled">
                            Activated
                          </button>
                        ) : (
                          <button
                            className="btn btn-light"
                            onClick={() => handleCheckout(plan)}
                          >
                            Subscribe Now
                          </button>
                        )}
                        <div className="plan-footer">Cancel Anytime.</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PurchasePlan;
