import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import "./UserProfileUpdate.css";
import { updateProfile } from "../../../services/user.service";
import { UserContext } from "../../../providers/UserContext";

const UserProfileUpdate = ({ isOpen, onClose }) => {
  const { currentUser, updateCurrentUser } = useContext(UserContext);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  useEffect(() => {
    if (currentUser) {
      setFormData((prevData) => ({
        ...prevData,
        first_name: currentUser.first_name || "",
        last_name: currentUser.last_name || "",
        email: currentUser.email || "",
      }));
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = currentUser?.access;
      if (!accessToken) {
        throw new Error("Access token is missing. Please log in again.");
      }

      const data = await updateProfile(accessToken, {
        first_name: formData.first_name,
        last_name: formData.last_name,
      });

      if (data) {
        toast.success("Profile Updated Successfully");
        const updated = {
          ...currentUser,
          first_name: formData.first_name,
          last_name: formData.last_name,
        };
        updateCurrentUser(updated);
        onClose();
      }
    } catch (error) {
      toast.error(
        error.message || "Failed to update profile. Please try again."
      );
    }
  };

  if (!isOpen) return null;

  return (
    <div className="user-profile-update-overlay">
      <div className="user-profile-update-content">
        <div className="user-profile-update-header">
          <h2 className="user-profile-update-heading">Update Profile</h2>
          <button
            aria-hidden="true"
            onClick={onClose}
            className="user-profile-update-close-btn"
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-2">
            <label className="user-profile-update-label">First Name</label>
            <input
              type="text"
              name="first_name"
              className="form-control"
              placeholder="Enter your first name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mt-2">
            <label className="user-profile-update-label">Last Name</label>
            <input
              type="text"
              name="last_name"
              className="form-control"
              placeholder="Enter your last name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="form-group mt-2">
            <label className="user-profile-update-label">Last Name</label>
            <input
              type="text"
              name="email"
              className="form-control"
              placeholder="Enter your last name"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div> */}

          <button type="submit" className="user-profile-update-btn mt-4">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserProfileUpdate;
