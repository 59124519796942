// import React, { useContext, useEffect, useState } from "react";

// import HeaderComponent from "../../components/Header/Header";
// import { Modal } from "react-bootstrap";
// import WordPerMinute from "../../assets/Images/Words-minute.png";
// import level from "../../assets/Images/Level.png";
// import topicImg from "../../assets/Images/Topic.png";
// import CreatdImg from "../../assets/Images/Created.png";
// import images from "../../images";

// import { useNavigate, useParams } from "react-router";
// import Footer from "../../components/Footer/Footer";
// import { fetchTranscriptService } from "../../services/transcript.service";
// import { UserContext } from "../../providers/UserContext";
// import { addInSharedPracticesService } from "../../services/vocab.service";
// import { FormContext } from "../../providers/FormContext";
// import PracticeShareModal from "../../components/Modal/PractiseShareModal/PractiseShareModal";
// const PracticeShare = () => {
//   const { currentUser } = useContext(UserContext);
//   const { resetFormData } = useContext(FormContext);

//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [modalShow, setModalShow] = useState(false);
//   // const [transcript, setTranscript] = useState([]);
//   const [topic, setTopic] = useState("");
//   const [practicesUser, setPracticesUser] = useState("");

//   const [wpm, setWpm] = useState("100");

//   const accessToken = localStorage.getItem("accessToken");

//   useEffect(() => {
//     if (currentUser) {
//       fetchTranscriptText();
//     }
//   }, [accessToken, currentUser]);

//   const fetchTranscriptText = async () => {
//     if (!accessToken) {
//       setModalShow(true);
//       return;
//     }
//     try {
//       const transcript = await fetchTranscriptService(id, accessToken);
//       if (transcript) {
//         setTopic(transcript.keyword);
//         const audios = transcript.save_audios;
//         const audio = audios.find((item) => item.audio_file_path != null);
//         if (audio) {
//           setWpm(audio.wpm);
//         }
//         if (transcript.user_details) {
//           setPracticesUser(transcript.user_details);
//         }
//         console.log("calling api", transcript);

//         AddInSharedPractices();
//       }
//     } catch (error) {
//       console.error("Error fetching the transcript:", error);
//     }
//   };

//   const AddInSharedPractices = async () => {
//     const payload = {
//       identifier: id,
//       email: currentUser.email,
//     };
//     await addInSharedPracticesService(payload, currentUser.access);
//   };

//   const drillVocabularyClick = () => {
//     navigate(`/game-question-and-flash-card/${id}`);
//   };
//   const interpretClick = () => {
//     navigate(`/transcript-with-audio/view/${id}`);
//   };
//   const evaluateClick = () => {
//     navigate(`/audio-interpret/${id}`);
//   };

//   const getStartClick = () => {
//     // Store the current URL before navigating to the login page
//     localStorage.setItem("redirectAfterLogin", window.location.pathname);
//     navigate(`/signup`);
//   };

//   const openLogin = () => {
//     // Store the current URL before navigating to the login page
//     localStorage.setItem("redirectAfterLogin", window.location.pathname);
//     navigate(`/login`);
//   };

//   const formatDate = (dateString) => {
//     const options = { year: "numeric", month: "long", day: "numeric" };
//     const date = new Date(dateString);
//     return date.toLocaleDateString(undefined, options);
//   };
//   const GeneratePractiseclik = () => {
//     resetFormData();
//     navigate("/vocabulary-screen");
//   };

//   return (
//     <div className="min-screen get-started">
//       {accessToken ? <HeaderComponent /> : ""}
//       <div className="mainContentBox">
//         <div className="practise-genrate-share-page">
//           <button
//             onClick={GeneratePractiseclik}
//             className="btn btn-success"
//             style={{ textTransform: "math-auto" }}
//           >
//             Generate a practice &nbsp;
//             <img src={images.pluscircle} alt="plus-circle-icon" />
//           </button>
//         </div>
//         <div className="main-content">
//           <div className="get-container">
//             <h1 className="title-practise-share">
//               {" "}
//               {topic ? topic.practice_name : "Practice 1"}
//             </h1>
//             <h4 className="share-practise-para">
//               An Interpreting Practice Has Been Shared With You. Happy Training!
//             </>
//             <div className="take-a-look">
//               {practicesUser && (
//                 <h4 className="get-started-title">
//                   {practicesUser.first_name} has shared a practice with you.
//                   Please take a look
//                 </h4>
//               )}
//             </div>
//             <div className="row">
//               <div className="col-lg-3">
//                 <div className="get-card">
//                   <div className="get-body">
//                     <img src={WordPerMinute} alt="Words-minute" />
//                     <h2 className="title">Words Per Minute</h2>
//                   </div>
//                   <h6 className="text">{wpm}</h6>
//                 </div>
//               </div>
//               <div className="col-lg-3">
//                 <div className="get-card">
//                   <div className="get-body">
//                     <img src={level} alt="level" />
//                     <h2 className="title">Level</h2>
//                   </div>
//                   <h6 className="text">
//                     {topic && topic.is_complete === true
//                       ? topic.level
//                       : "Intermediate"}
//                   </h6>
//                 </div>
//               </div>
//               <div className="col-lg-3">
//                 <div className="get-card">
//                   <div className="get-body">
//                     <img src={topicImg} alt="Topic" />
//                     <h2 className="title">Topic</h2>
//                   </div>
//                   <h6 className="text">{topic ? topic.topic : "Topic"}</h6>
//                 </div>
//               </div>
//               <div className="col-lg-3">
//                 <div className="get-card">
//                   <div className="get-body">
//                     <img src={CreatdImg} alt="Created" />
//                     <h2 className="title">Created On</h2>
//                   </div>
//                   <h6 className="text">
//                     {formatDate(topic ? topic.created_at : new Date())}
//                   </h6>
//                 </div>
//               </div>
//             </div>
//             <div className="btn-container">
//               <div className="row justify-content-center">
//                 <div className="col-lg-3 col-md-4">
//                   <button
//                     className="btn btn-success"
//                     onClick={drillVocabularyClick}
//                   >
//                     Drill vocabulary
//                   </button>
//                 </div>
//                 <div className="col-lg-3 col-md-4">
//                   <button className="btn btn-success" onClick={interpretClick}>
//                     Interpret & Record
//                   </button>
//                 </div>
//                 <div className="col-lg-3 col-md-4">
//                   <button className="btn btn-success" onClick={evaluateClick}>
//                     {" "}
//                     Evaluate
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//       <PracticeShareModal
//         modalShow={modalShow}
//         setModalShow={setModalShow}
//         getStartClick={getStartClick}
//         openLogin={openLogin}
//       />
//     </div>
//   );
// };

// export default PracticeShare;
import React, { useContext, useEffect, useState } from "react";

import HeaderComponent from "../../components/Header/Header";
import { Modal } from "react-bootstrap";
import WordPerMinute from "../../assets/Images/Words-minute.png";
import level from "../../assets/Images/Level.png";
import topicImg from "../../assets/Images/Topic.png";
import CreatdImg from "../../assets/Images/Created.png";
import "./PracticeShare.css";
import images from "../../images";

import { useNavigate, useParams } from "react-router";
import Footer from "../../components/Footer/Footer";
import {
  fetchTranscriptService,
  fetchTranscriptServicePublic,
} from "../../services/transcript.service";
import { UserContext } from "../../providers/UserContext";
import {
  addInSharedPracticesService,
  fetchTopicsService,
} from "../../services/vocab.service";
import { FormContext } from "../../providers/FormContext";
import PracticeShareModal from "../../components/Modal/PractiseShareModal/PractiseShareModal";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SubscriptionModal from "../../components/Modal/Subscription/Subscription";
import { PlanContext } from "../../providers/PlanContext";
import StorageAndPracticeLimit from "../../components/Modal/StorageAndPracticeLimit/StorageAndPracticeLimit";
import GeneratePracticeButton from "../../components/GeneratePractise/GeneratePracticeButton";
import { PracticeContext } from "../../providers/PractiseContext";

const PracticeShare = (totalPractise, totalStorage) => {
  const { currentUser } = useContext(UserContext);
  const { resetFormData, updateFormData } = useContext(FormContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [showToast, setShowToast] = useState(true);

  // const [transcript, setTranscript] = useState([]);
  const [topic, setTopic] = useState("");
  const [practicesUser, setPracticesUser] = useState("");

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [wpm, setWpm] = useState("");
  const [popup, setPopup] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  const { remainingStorage } = useContext(PlanContext);
  const [totalpractise, setTotalpractise] = useState();
  const [totalstorage, setTotalstorage] = useState();
  const [
    showStorageAndPracticeLimitModal,
    setShowStorageAndPracticeLimitModal,
  ] = useState(false);

  const toastStatus = sessionStorage.getItem("toastStatus") ?? true;

  useEffect(() => {
    // Get the query string from the current URL
    const params = new URLSearchParams(window.location.search);

    // Get the value of the 'popup' query parameter
    const popupValue = params.get("popup") ?? true;

    // Set the state with the popup value
    setPopup(popupValue);
  }, []);

  useEffect(() => {
    // if (currentUser) {
    fetchTranscriptText();
  }, [accessToken, currentUser]);

  useEffect(() => {
    showGettingtoaster();
  }, [currentUser]);

  const fetchTranscriptText = async () => {
    // return;
    if (!accessToken) {
      try {
        const transcript = await fetchTranscriptServicePublic(id);
        if (transcript) {
          setTopic(transcript.keyword);
          const audios = transcript.save_audios;
          const audio = audios.find((item) => item.audio_file_path != null);
          if (audio) {
            setWpm(audio.wpm);
          }
          if (transcript.user_details) {
            setPracticesUser(transcript.user_details);
          }
        }
      } catch (error) {
        console.error("Error fetching the transcript:", error);
      }
      setModalShow(true);
      return;
    }
    try {
      const transcript = await fetchTranscriptService(id, accessToken);

      if (transcript) {
        setTopic(transcript.keyword);
        const audios = transcript.save_audios;
        const audio = audios.find((item) => item.audio_file_path != null);
        if (audio) {
          setWpm(audio.wpm);
        }
        if (transcript.user_details) {
          setPracticesUser(transcript.user_details);
        }
        // console.log("calling api", transcript);
        // add to user's shared practice list
        AddInSharedPractices();
      }
    } catch (error) {
      console.error("Error fetching the transcript:", error);
    }
  };

  const AddInSharedPractices = async () => {
    const payload = {
      identifier: id,
      email: currentUser.email,
    };
    await addInSharedPracticesService(payload, currentUser.access);
  };

  const drillVocabularyClick = () => {
    // if (
    //   currentUser &&
    //   currentUser.first_name &&
    //   currentUser.subscription_status
    // ) {
    navigate(`/game-question-and-flash-card/${id}`);
    // } else {
    //   setShowSubscriptionModal(true);
    // }

    // return;
  };
  const interpretClick = () => {
    // if (
    //   currentUser &&
    //   currentUser.first_name &&
    //   currentUser.subscription_status
    // ) {
    // navigate(`/transcript-with-audio/view/${id}`);
    navigate(`/audio-interpret/${id}`);
    // } else {
    //   setShowSubscriptionModal(true);
    // }
    // return;
  };
  const evaluateClick = () => {
    // if (
    //   currentUser &&
    //   currentUser.first_name &&
    //   currentUser.subscription_status
    // ) {
    // navigate(`/audio-player/${id}`);
    navigate(`/recording-with-keyword/${id}`);
    // } else {
    //   setShowSubscriptionModal(true);
    // }
    // return;
  };

  const getStartClick = () => {
    const redirectUrl = `${window.location.pathname}?popup=false`;
    // Store the current URL before navigating to the login page
    localStorage.setItem("redirectAfterLogin", redirectUrl);
    sessionStorage.setItem(
      "practiceValidationText",
      "After subscribing, you will have 25 practice generations available in your account, which reset to 25 at the start of each monthly billing cycle."
    );
    navigate(`/signup`);
  };

  const openLogin = () => {
    const redirectUrl = `${window.location.pathname}?popup=false`;
    // Store the current URL before navigating to the login page
    localStorage.setItem("redirectAfterLogin", redirectUrl);
    sessionStorage.setItem(
      "practiceValidationText",
      "After subscribing, you will have 25 practice generations available in your account, which reset to 25 at the start of each monthly billing cycle."
    );

    navigate(`/login`);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    setTotalpractise(remainingStorage.totalPractice);
    setTotalstorage(remainingStorage.totalStorage);
  }, [remainingStorage]);

  // const GeneratePractiseclik = () => {
  //   if (
  //     currentUser &&
  //     currentUser.first_name &&
  //     currentUser.subscription_status
  //   ) {
  //     resetFormData();
  //     if (totalpractise > 0 && totalstorage > 0) {
  //       if (
  //         totalpractise <
  //           process.env.REACT_APP_SHOW_NOTIFICATION_PRACTICE_LIMIT ||
  //         totalstorage < process.env.REACT_APP_SHOW_NOTIFICATION_STORAGE_LIMIT
  //       ) {
  //         toast.info(
  //           `You are nearing your usage limits. You have currently remaining ${totalpractise} practices and ${totalstorage} MB of storage. Please upgrade your plan to continue using this feature without interruptions.`,
  //           {
  //             className: "custom-toast",
  //             bodyClassName: "custom-toast-body",
  //             position: "top-center",
  //           }
  //         );
  //       }
  //       navigate("/vocabulary-screen");
  //     } else {
  //       setShowStorageAndPracticeLimitModal(true);
  //     }
  //   } else {
  //     setShowSubscriptionModal(true);
  //   }
  //   return;
  // };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Adjust scroll threshold as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const showGettingtoaster = () => {
    if (toastStatus === true && !accessToken) {
      sessionStorage.setItem("toastStatus", false);

      toast.info(
        " An Interpreting Practice Has Been Shared With You! Happy Training!",
        {
          // className: "toast-share-center",
          className: "custom-toast",
          bodyClassName: "custom-toast-body",
          autoClose: false,
          position: "top-center",

          icon: (
            <img
              src={images.Partyicon}
              alt="Custom Icon"
              style={{ width: 24, height: 24 }}
            />
          ),
          // icon: false,
        }
      );
      setShowToast(false);
    }
    if (
      toastStatus === true &&
      currentUser &&
      practicesUser &&
      currentUser.first_name !== practicesUser.first_name &&
      currentUser.last_name !== practicesUser.last_name
    ) {
      console.log("toastStatus", toastStatus);
      sessionStorage.setItem("toastStatus", false);
      toast.info(
        " An Interpreting Practice Has Been Shared With You! Happy Training!",
        {
          // className: "toast-share-center",
          className: "custom-toast",
          bodyClassName: "custom-toast-body",
          autoClose: false,
          position: "top-center",

          icon: (
            <img
              src={images.Partyicon}
              alt="Custom Icon"
              style={{ width: 24, height: 24 }}
            />
          ),
          // icon: false,
        }
      );
      setShowToast(false);
    }
  };
  useEffect(() => {
    if (accessToken) {
      // Dismiss the toast if accessToken is available
      toast.dismiss();
    }
  }, [accessToken]);

  return (
    <div className="min-screen get-started">
      {/* {
        currentUser &&
          currentUser.first_name !== practicesUser.first_name &&
          currentUser.last_name !== practicesUser.last_name &&
          // <div className={`bar-strip ${isScrolled ? "hidden" : ""}`}>
          //   <h3 style={{ fontSize: "18px" }}>
          //     An Interpreting Practice Has Been Shared With You! Happy Training!
          //   </h3>
          // </div>
         
        //   </h3>")
      } */}

      {/* <div className={`practice-page ${isScrolled ? "sticky-header" : ""}`}> */}
      {accessToken ? <HeaderComponent /> : ""}
      {/* </div> */}
      <div className="mainContentBox shared-practise-div">
        <div className="practise-genrate-share-page">
          {/* <button
            onClick={GeneratePractiseclik}
            className="btn btn-success"
            style={{ textTransform: "none" }}
          >
            Generate a Practice &nbsp;
            <img src={images.pluscircle} alt="plus-circle-icon" />
          </button> */}
          <GeneratePracticeButton
            setLanguageDefaultValue={() => {}}
            setShowStorageAndPracticeLimitModal={
              setShowStorageAndPracticeLimitModal
            }
            setShowSubscriptionModal={setShowSubscriptionModal}
            isListItem={false}
          />
        </div>
        <div className="main-content sharePractice">
          <h1 className="title">
            {" "}
            {topic ? (
              topic.practice_name
            ) : (
              <Skeleton
                className="skeleton-css"
                width={100}
                customHighlightBackground="linear-gradient(90deg, var(--base-color) 40%, var(--highlight-color) 50%, var(--base-color) 60%)"
              />
            )}
          </h1>
          {/* <h4 className="share-practise-para">
            An Interpreting Practice Has Been Shared With You. Happy Training!
          </h4> */}
          {/* <div>
            {practicesUser && (
              <h4 className="get-started-title">
                {practicesUser.first_name} has shared a practice with you.
                Please take a look
              </h4>
            )}
          </div> */}

          <div className="get-container">
            <div className="row">
              <div className="col-lg-3">
                <div className="get-card">
                  <div className="get-body">
                    <img src={WordPerMinute} alt="Words-minute" />
                    <div>
                      <p className="text">Words Per Minute</p>
                      <h2 className="title">
                        {wpm ? (
                          wpm
                        ) : (
                          <Skeleton
                            className="skeleton-css"
                            customHighlightBackground="linear-gradient(90deg, var(--base-color) 40%, var(--highlight-color) 50%, var(--base-color) 60%)"
                          />
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="get-card">
                  <div className="get-body">
                    <img src={level} alt="level" />

                    <div>
                      <p className="text">Level</p>
                      <h2 className="title">
                        {topic && topic.is_complete === true ? (
                          topic.level
                        ) : (
                          <Skeleton
                            className="skeleton-css"
                            customHighlightBackground="linear-gradient(90deg, var(--base-color) 40%, var(--highlight-color) 50%, var(--base-color) 60%)"
                          />
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="get-card">
                  <div className="get-body">
                    <img src={topicImg} alt="Topic" />
                    <div>
                      <p className="text">Topic</p>
                      <h2 className="title ">
                        {topic ? (
                          topic.topic
                        ) : (
                          <Skeleton
                            className="skeleton-css"
                            customHighlightBackground="linear-gradient(90deg, var(--base-color) 40%, var(--highlight-color) 50%, var(--base-color) 60%)"
                          />
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="get-card">
                  <div className="get-body">
                    <img src={CreatdImg} alt="Created" />
                    <div>
                      <p className="text">Created On</p>
                      <h2 className="title">
                        {topic ? (
                          formatDate(topic.created_at)
                        ) : (
                          <Skeleton
                            className="skeleton-css"
                            customHighlightBackground="linear-gradient(90deg, var(--base-color) 40%, var(--highlight-color) 50%, var(--base-color) 60%)"
                          />
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-container">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-4">
                  <button
                    className="btn btn-success"
                    onClick={drillVocabularyClick}
                  >
                    Drill vocabulary
                  </button>
                </div>
                <div className="col-lg-3 col-md-4">
                  <button className="btn btn-success" onClick={interpretClick}>
                    Interpret & Record
                  </button>
                </div>
                <div className="col-lg-3 col-md-4">
                  <button className="btn btn-success" onClick={evaluateClick}>
                    {" "}
                    Evaluate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* <Modal
        className="practiceShareModal"
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        backdrop="static"
      >
        <Modal.Body>
          <button className="btn btn-primary" onClick={getStartClick}>
            Get started Free
          </button>
          <p>
            Already have an account?{" "}
            <button onClick={openLogin} className="buton-loginhere">
              log in here
            </button>
          </p>
        </Modal.Body>
      </Modal> */}
      <PracticeShareModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        getStartClick={getStartClick}
        openLogin={openLogin}
      />

      {showSubscriptionModal && (
        <SubscriptionModal
          show={showSubscriptionModal}
          onHide={() => setShowSubscriptionModal(false)}
        />
      )}
      {showStorageAndPracticeLimitModal && (
        <StorageAndPracticeLimit
          show={showStorageAndPracticeLimitModal}
          handleClose={() => setShowStorageAndPracticeLimitModal(false)}
        />
      )}
    </div>
  );
};

export default PracticeShare;
