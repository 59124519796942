import React from "react";
import images from "../../../images";
import "./VocabCopy.css";

const ConfirmCopyModal = ({ showModal, onClose, onCopy, message }) => {
  return (
    <>
      {showModal && <div className="modal-backdrop-blur" />}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-item">
                <img
                  src={images.copy}
                  alt="delete"
                  style={{ background: "green" }}
                />
                {/* <h4>Are you sure?</h4> */}
                <p>{message}</p>
              </div>
              <div className="modal-btn-group">
                <button type="button" className="deletbtn" onClick={onClose}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  style={{ margin: "0px" }}
                  onClick={onCopy}
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmCopyModal;
