import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import Button from "components/Button/SignupButton";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";

const VocabularyScreen = () => {
  const navigate = useNavigate();
  const { formData, updateFormData } = useContext(FormContext);
  const [vocabularyCount, setVocabularyCount] = useState(
    formData.vocabularyCount || ""
  );
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setVocabularyCount(value);

    updateFormData("vocabularyCount", value);
  };

  const validateInput = () => {
    const count = parseInt(vocabularyCount, 10);

    if (vocabularyCount.trim() === "") {
      setError("Vocabulary count cannot be empty.");
      toast.error("Vocabulary count cannot be empty.");
      return false;
    }

    if (isNaN(count)) {
      setError("Please enter a valid number.");
      toast.error("Please enter a valid number.");
      return false;
    }

    if (count <= 4 || count > 25) {
      setError(
        "Please enter a number greater than or equal to 5 and less than or equal to 25."
      );
      toast.error(
        "Please enter a number greater than or equal to 5 and less than or equal to 25."
      );
      return false;
    }

    setError("");
    return true;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (validateInput()) {
      setVocabularyCount("");
      navigate("/select-language");
    }
  };

  return (
    <div className="min-screen screen3">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">
            Vocabulary Flashcards for Interpreter Practice
          </h1>
          <p className="des">
            To create a tailored set of vocabulary flashcards for your
            interpreter practice, please answer the following questions. You can
            skip any question if you prefer, but more details will help us
            create a more precise practice experience.
          </p>
          <form className="form_">
            <label className="form-label">
              How many vocabulary terms do you want to drill in this practice?{" "}
              {/* <span className="text-danger">*</span> */}
            </label>
            <input
              type="number"
              value={vocabularyCount}
              onChange={handleChange}
              className="form-control"
              placeholder="(Ex: 15)"
            />
          </form>
          {error && (
            <div className="fistpage-error-message">
              <span>{error}</span>
            </div>
          )}
          <div className="btn-list">
            <Link to="/practice-dashboard" className="noDecoration">
              <Button className="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </Button>
            </Link>
            <Link className="noDecoration">
              <Button className="small green-btn" onClick={handleNextClick}>
                next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VocabularyScreen;
