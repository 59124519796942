import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HeaderComponent from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { loadStripe } from "@stripe/stripe-js";
import "./PlanDetails.css"; // Import your new stylesheet for custom styles
import { saveSubscription } from "../../../services/subscription.service";
import { UserContext } from "../../../providers/UserContext";

const plans = [
  {
    id: 1,
    name: "Basic Plan",
    price: 19.0,
    practice: 10,
    priceId: "price_1QWu9RGbC7iSAhr2kH227DkS",
    type: "subscription",
    storage: "500 MB",
    billingInterval: "Monthly",
    details:
      "AI generated Vocabulary, AI generated Transcript, AI generated Audio",
  },
];

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const PlanDetails = () => {
  const { id } = useParams();
  const { currentUser } = useContext(UserContext);
  const plan = plans.find((plan) => plan.id === parseInt(id));

  const handleCheckout = async () => {
    if (!currentUser) {
      console.error("User is not logged in");
      return; // Prevent the checkout process if no user is logged in
    }
    try {
      // Call the service to save the order details
      //   const userId =currentUser.email; // Replace with the actual user ID
      //   const response = await saveSubscription(userId, plan.id, plan.price, currentUser.access);

      // Proceed with Stripe checkout
      const paymentId = "fgvsffg364643";
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        lineItems: [
          { price: plan.priceId, quantity: 1 }, // Replace with your Price ID
        ],
        mode: "payment",
        successUrl: `http://localhost:3000/payment-success/${paymentId}`,
        cancelUrl: `http://localhost:3000/payment-cancel/${paymentId}`,
        // metadata: {
        //     payment_id: paymentId, // Custom value
        //   },
      });
    } catch (error) {
      console.error("Error during checkout:", error);
      // Optionally show an error message to the user
    }
  };

  if (!plan) {
    return <h3>Plan not found!</h3>;
  }

  return (
    <div className="plan-details-container-main">
      <HeaderComponent />

      <div className="plan-details-content-main">
        <div className="card">
          <div className="card-body">
            <h2 className="plan-title">{plan.name}</h2>

            <div className="plan-details">
              <p>
                <strong>Price:</strong> ${plan.price}/mo
              </p>
              <p>
                <strong>Practice:</strong> {plan.practice}
              </p>
              <p>
                <strong>Type:</strong> {plan.type}
              </p>
              <p>
                <strong>Storage:</strong> {plan.storage}
              </p>
              <p>
                <strong>Billing Interval:</strong> {plan.billingInterval}
              </p>

              <div className="plan-details-list">
                <p>
                  <strong>Details:</strong>
                </p>
                <ul>
                  {plan.details.split(", ").map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              </div>

              <button className="pay-now-button" onClick={handleCheckout}>
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PlanDetails;
