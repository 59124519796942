import React, { useContext, useEffect, useState } from "react";
import "./InfoModal.css";
import { PlanContext } from "../../../providers/PlanContext";
import { useNavigate } from "react-router";

const InfoModal = ({ show, handleClose, header, content }) => {
  const { remainingStorage } = useContext(PlanContext);
  const [totalpractise, setTotalpractise] = useState();
  const [totalstorage, setTotalstorage] = useState();
  const navigate = useNavigate();
  const { planDetails } = useContext(PlanContext);
  const validationText = sessionStorage.getItem("practiceValidationText");

  useEffect(() => {
    setTotalpractise(remainingStorage.totalPractice);
    setTotalstorage(remainingStorage.totalStorage);
  }, [remainingStorage]);

  if (!show) {
    return null;
  }

  return (
    <div className="info-modal-overlay">
      <div className="info-modal">
        <div className="info-modal-header">
          <h3>{header}</h3>
          <button className="info-modal-close-btn" onClick={handleClose}>
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
        <div className="info-modal-body">
          <h3 className="remaining-credit-limit mb-1">
            {planDetails &&
              planDetails.lastPlan &&
              (header === "Storage"
                ? `Remaining Storage: ${totalstorage} MBs`
                : `Remaining Practices: ${totalpractise}`)}
          </h3>

          <p>{header === "Storage" ? content : validationText ?? content}</p>
          {/* {planDetails && planDetails.lastPlan ? ( */}
          {header === "Storage" ? (
            planDetails && planDetails.lastPlan ? (
              <button
                className="storage-practice-limit-modal-btn mt-2"
                onClick={() => navigate("/purchase-storage")}
              >
                Buy Storage
              </button>
            ) : (
              ""
            )
          ) : (
            !planDetails?.lastPlan && (
              <button
                className="storage-practice-limit-modal-btn mt-2"
                onClick={() => navigate("/purchase-plan")}
              >
                Buy Plan
              </button>
            )
          )}
          {/* ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
