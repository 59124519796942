import React, { useContext, useEffect, useState } from "react";
import HeaderComponent from "../../components/Header/Header";

import SwiperComponent from "../../components/SwiperSlider/Swiper";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router";
import { UserContext } from "../../providers/UserContext";
import SubscriptionModal from "../../components/Modal/Subscription/Subscription";
import { PlanContext } from "../../providers/PlanContext";
import { toast } from "react-toastify";
import StorageAndPracticeLimit from "../../components/Modal/StorageAndPracticeLimit/StorageAndPracticeLimit";

function WelcomePage() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { remainingStorage } = useContext(PlanContext);
  const [totalpractise, setTotalpractise] = useState();
  const [totalstorage, setTotalstorage] = useState();
  const [showStorageAndPracticeLimitModal, setShowStorageAndPracticeLimitModal] = useState(false);

  useEffect(() => {
    setTotalpractise(remainingStorage.totalPractice);
    setTotalstorage(remainingStorage.totalStorage);
  }, [remainingStorage]);

  const GetstartClicked = () => {
    if (currentUser.subscription_status) {
      if (totalpractise > 0 && totalstorage > 0) {
        if (totalpractise < process.env.REACT_APP_SHOW_NOTIFICATION_PRACTICE_LIMIT || totalstorage < process.env.REACT_APP_SHOW_NOTIFICATION_STORAGE_LIMIT) {
          toast.info(
            <div>
                You are approaching your storage limit. Delete any of your <a 
                  href="/purchase-plan" 
                  style={{textDecoration: 'underline', marginLeft: '5px' }}
                >practices {" "}
                </a>
                or recordings to make more space or purchase additional 
                <a 
                  href="/purchase-storage" 
                  style={{textDecoration: 'underline', marginLeft: '5px' }}
                >storage {" "}
                </a>
                100 MB for a one-time fee of $2.99.
            </div>,
            {
              className: "custom-toast",
              bodyClassName: "custom-toast-body",
              position: "top-center",
            }
          );
        }
        navigate("/vocabulary-screen");
      } else {
        setShowStorageAndPracticeLimitModal(true);
      }
    } else {
      setShowSubscriptionModal(true);
    }
  };
  return (
    <div className="min-screen screen2 welcome-slider">
      <HeaderComponent />
      <div className="welcome-mainContentBox">
        <div className="slider-content">
          <h1 className="title">Welcome to Interpreter Pro AI!</h1>
          <p className="des">
            Click below to start creating your customized interpreting lab.
          </p>
          <button className="btn btn-light" onClick={GetstartClicked}>
            Get Started
          </button>
          <SwiperComponent />
        </div>
      </div>

      <Footer />
      {showSubscriptionModal && (
        <SubscriptionModal
          show={showSubscriptionModal}
          onHide={() => setShowSubscriptionModal(false)}
        />
      )}
      {showStorageAndPracticeLimitModal&& (
        <StorageAndPracticeLimit
          show={showStorageAndPracticeLimitModal}
          handleClose={() => setShowStorageAndPracticeLimitModal(false)}
        />
      )}
    </div>
  );
}

export default WelcomePage;
