
import React, { createContext, useEffect, useState } from 'react';
import { getUserDetails } from '../services/user.service';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState({});
    const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        const loadUser = async () => {
            if (accessToken) {
                try {
                    const profile = await getUserDetails(accessToken);
                    if (profile) {
                        setCurrentUser(profile);
                    } else {
                        setCurrentUser({});
                        logout();
                    }
                } catch (error) {
                    console.log("error :", error);
                    console.log("error status:", error.response.status);
                    if (error.response.status === 403) {
                        setCurrentUser({});
                        logout();
                    }
                }
            } else {
                setCurrentUser({});
            }
        };
        loadUser();
    }, [accessToken]);
    const updateCurrentUser = (userData) => {
        setCurrentUser(userData);
    };

    const resetCurrentUser = () => {
        setCurrentUser({});
    };

    const logout = () => {
        localStorage.clear();
    };


    return (
        <UserContext.Provider value={{ currentUser, updateCurrentUser, resetCurrentUser }}>
            {children}
        </UserContext.Provider>
    );
};
