import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/css/Main.css"

import reportWebVitals from './reportWebVitals';
import IndexRoutes from './Routes/Mainroutes';
import { FormProvider } from './providers/FormContext';
import { UserProvider } from './providers/UserContext';

import { Provider } from 'react-redux';
import store from './redux/store';
import VoiceCatalog from './pages/VoiceGeneration/VoiceList/VoiceCatlog';
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import the CSS for react-toastify
import { PracticeProvider } from './providers/PractiseContext';
import { PlanProvider } from './providers/PlanContext';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <Provider store={store}>

      <UserProvider>
        <FormProvider>
          <PracticeProvider>
            <PlanProvider> {/* Add PlanProvider */}
              <IndexRoutes />
              <ToastContainer />
            </PlanProvider>
          </PracticeProvider>
        </FormProvider>
      </UserProvider>

    </Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
