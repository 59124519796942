import React, { useContext, useEffect, useState } from "react";
import "./Transcript.css";
import HeaderComponent from "components/Header/Header";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import Footer from "../../../components/Footer/Footer";
import TranscriptSection from "../../../components/TranscriptComponent/TranscriptComponent";
import { fetchTranscriptService } from "../../../services/transcript.service";
import { UserContext } from "../../../providers/UserContext";

const TranscriptPage = () => {
  const [transcriptText, setTranscriptText] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useContext(UserContext);
  useEffect(() => {
    fetchTranscriptText();
  }, [id, currentUser]);

  // const accessToken = localStorage.getItem("accessToken");

  // const fetchTranscriptText = async () => {
  //   try {
  //     const response = await axios.post(
  //       REACT_APP_GET_TRANSCRIPT_API,
  //       {
  //         identifier: id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );
  //     if (response.data && response.data.transcripts[0]) {
  //       setTranscriptText(response.data.transcripts[0].transcript);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching the transcript:", error);
  //   }
  // };
  const fetchTranscriptText = async () => {
    // if (!accessToken) {
    //   setModalShow(true);
    //   return;
    // }

    try {
      const transcriptData = await fetchTranscriptService(
        id,
        currentUser.access
      );

      // console.log(transcriptData, "transcriptData");
      if (transcriptData && transcriptData.transcript.transcript) {
        setTranscriptText(
          transcriptData.transcript.transcript.replace(/<break\/>/g, "\n \n")
        );
      }
    } catch (error) {
      console.error("Error fetching the transcript:", error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="transcript-container-div">
      <HeaderComponent />
      <div className="mainContentBox-trancript">
        <h1 className="transcript-title">Transcript</h1>
        <main className="main-content">
          <TranscriptSection transcriptText={transcriptText} />

          <div className="btn-list">
            <Button className="transcript-small-btn" onClick={handleBack}>
              BACK
            </Button>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default TranscriptPage;
