import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";

const TranscriptSpeaker = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [speaker, setSpeaker] = useState(formData.speaker_info || "");
  const [person1, setPerson1] = useState(formData.person1 || "");
  const [person1details, setPerson1details] = useState(
    formData.person1details || ""
  );
  const [locationDetails, setLocationDetails] = useState(
    formData.locationDetails || ""
  );

  const [professionDetails, setProfessionDetails] = useState(
    formData.professionDetails || ""
  );

  const [person1Error, setPerson1Error] = useState("");

  const [person2Error, setPerson2Error] = useState("");
  const [person3Error, setPerson3Error] = useState("");

  const [person2, setPerson2] = useState(formData.person2 || "");

  const [conversationType] = useState(formData.conversationType || "");

  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSpeaker(value);
    updateFormData("speaker_info", value);
  };

  const handleInputSpeaker1Change = (e) => {
    const value = e.target.value;
    setPerson1(value);
    updateFormData("person1", value);
  };
  const handleInputSpeaker1detailsChange = (e) => {
    const value = e.target.value;
    setPerson1details(value);
    updateFormData("person1_details", value);
  };
  const handleLocationDetailsChange = (e) => {
    const value = e.target.value;
    setLocationDetails(value);
    updateFormData("location_details", value);
  };
  const handleInputSpeaker2Change = (e) => {
    const value = e.target.value;
    setPerson2(value);
    updateFormData("person2", value);
  };

  const handleInputProfession = (e) => {
    const value = e.target.value;
    setProfessionDetails(value);
    updateFormData("profession_details", value);
  };

  const validateInput = () => {
    if (conversationType === "speech") {
      if (speaker.trim() === "") {
        setPerson1Error("Speaker information cannot be empty.");
        toast.error("Speaker information cannot be empty.");
        return false;
      }
      if (locationDetails.trim() === "") {
        setPerson3Error("Location detail cannot be empty.");
        toast.error("Location detail cannot be empty.");
        return false;
      }
      if (professionDetails.trim() === "") {
        setPerson3Error("Profession detail cannot be empty.");
        toast.error("Profession detail cannot be empty.");
        return false;
      }
      setError("");
      return true;
    } else {
      if (person1.trim() === "") {
        setPerson1Error("Please provide the information for Character 1.");
        toast.error("Please provide the information for Character 1.");
        return false;
      } else {
        setPerson1Error("");
      }
      if (person1details.trim() === "") {
        setPerson1Error("Please provide the information for Character 1.");
        toast.error("Please provide the information for Character 1.");
        return false;
      } else {
        setPerson1Error("");
      }
      if (person2.trim() === "") {
        setPerson2Error("Please provide the information for Character 2.");
        toast.error("Please provide the information for Character 2.");
        return false;
      } else {
        setPerson2Error("");
      }

      return true;
    }
  };
  // console.log(formData, "formdata");
  const handleNextClick = (e) => {
    e.preventDefault();
    if (validateInput()) {
      navigate(`/transcript-additional-info/${id}`);
    }
  };

  return (
    <div className="min-screen screen13">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">Transcript Generation</h1>
          <p className="des">
            {conversationType === "speech"
              ? ` Please identify the speaker to help us accurately attribute and
            organize the dialogue in the transcript. Providing the speaker's
            name or role will enhance the clarity and usability of the
            transcript.`
              : `Help us customize your transcript further. The more details you provide about your characters, the better we can tailor it just for you!`}
          </p>
          <form className="form_">
            {conversationType === "speech" ? (
              <>
                {/* <label className="form-label">Who is the speaker?</label>
                <textarea
                  className="form-control"
                  rows="3"
                  value={speaker}
                  onChange={handleInputChange}
                  placeholder="Please specify the speaker or scenario for the transcript. (Ex: commencement speech speaker, politician at a campaign rally, CEO addressing shareholders, therapist in a counseling session, chef explaining a recipe, etc.)"
                  required
                ></textarea> */}
                <label className="form-label">
                  Character 1 (The Person Speaking)
                </label>
                <textarea
                  className="form-control"
                  value={speaker}
                  onChange={handleInputChange}
                  placeholder="Provide fictitious name."
                  required
                  rows="1"
                />
                {/*  */}
                {person1Error && (
                  <div className="trans-error-message mt-2">
                    <span>{person1Error}</span>
                  </div>
                )}
                <label className="form-label mt-2">
                  Character 2 (The Group)
                </label>

                <textarea
                  className="form-control mt-2"
                  value={locationDetails}
                  onChange={handleLocationDetailsChange}
                  placeholder="Describe location. (Ex: New York City,  Bogota,  New Delhi, etc. )"
                  required
                  rows="1"
                />
                <textarea
                  className="form-control mt-2"
                  value={professionDetails}
                  onChange={handleInputProfession}
                  placeholder="Describe the professional affiliation of the Group (Ex: court interpreters, back surgeons , industrial engineers, etc.) "
                  required
                  rows="1"
                />
                {person3Error && (
                  <div className="trans-error-message mt-2">
                    <span>{person3Error}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                <label className="form-label">
                  Character 1 (The Person Being Addressed)
                </label>
                <textarea
                  className="form-control"
                  value={person1}
                  onChange={handleInputSpeaker1Change}
                  placeholder="Enter a fictitious name here."
                  required
                  rows="1"
                />

                <textarea
                  className="form-control mt-2"
                  value={person1details}
                  onChange={handleInputSpeaker1detailsChange}
                  placeholder="Enter any details on the person's emotional state, age, role, etc."
                  required
                  rows="1"
                />
                {person1Error && (
                  <div className="trans-error-message mt-2">
                    <span>{person1Error}</span>
                  </div>
                )}
                <label className="form-label mt-2">
                  Character 2 (The Person Speaking)
                </label>
                <textarea
                  className="form-control"
                  value={person2}
                  onChange={handleInputSpeaker2Change}
                  placeholder="Enter any details on the person's emotional state, age, profession, role etc."
                  required
                  rows="1"
                />
              </>
            )}
          </form>
          {person2Error && (
            <div className="trans-error-message">
              <span>{person2Error}</span>
            </div>
          )}
          <div className="btn-list">
            <Link to={`/transcript-type/${id}`} className="noDecoration">
              <button className="small">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </button>
            </Link>
            <Link className="noDecoration">
              <button className="small green-btn" onClick={handleNextClick}>
                next
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TranscriptSpeaker;
