import React, { useContext } from "react";
import "./CurrentPlan.css";
import { Navigate, useNavigate } from "react-router";
import { PlanContext } from "../../../../providers/PlanContext";
const CurrentPlan = () => {
  const navigate = useNavigate();
  const { planDetails } = useContext(PlanContext);

  const PurchasePlan = () => {
    // if (planDetails && planDetails.lastPlan) {
    //   navigate("/purchase-storage");
    // } else {
    //   navigate("/purchase-plan");
    // }
    if (planDetails && planDetails.lastPlan) {
      if (planDetails.lastPlan.status === "Inactive") {
        navigate("/purchase-plan");
      } else {
        navigate("/purchase-storage");
      }
    } else {
      navigate("/purchase-plan");
    }
  };
  return (
    <div className="current-plan">
      <div className="profile-info-header-content">
        <h3>Current Plan</h3>

        <button className="profile-edit-button" onClick={PurchasePlan}>
          {!planDetails ||
          !planDetails.lastPlan ||
          planDetails.lastPlan.status === "Inactive"
            ? "Subscribe"
            : "Buy Storage"}
        </button>
      </div>
      {planDetails && planDetails.lastPlan ? (
        <table>
          <tbody>
            <tr>
              <th>Plan:</th>
              <td>{planDetails.lastPlan.subscription_name}</td>
            </tr>

            <tr>
              <th>Price:</th>
              <td>${planDetails.lastPlan.subscription_price}/month</td>
            </tr>

            <tr>
              <th>Storage:</th>
              <td>{planDetails.lastPlan.storage}s</td>
            </tr>
            <tr>
              <th>Status:</th>
              {/* <td>{planDetails.lastPlan.status}</td> */}
              <td
                style={{
                  color:
                    planDetails.lastPlan.status === "Inactive"
                      ? "red"
                      : "white",
                }}
              >
                {planDetails.lastPlan.status}
              </td>
            </tr>

            {/* <tr>
              <th>Total storage:</th>
              <td>{planDetails.totalStorage} MB</td>
            </tr> */}

            {/* <tr>
            <th>Features:</th>
            <td>
              AI-generated Vocabulary, AI-generated Transcript, AI-generated Audio
            </td>
          </tr> */}
          </tbody>
        </table>
      ) : (
        <p style={{ padding: "10px" }}>
          You currently have a free account, which allows you to receive shared
          interpreting practices from paid subscribers.{" "}
        </p>
      )}
    </div>
  );
};

export default CurrentPlan;
