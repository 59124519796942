import axios from "axios";
import { REACT_APP_CANCELED_SUBSCRIPTION, REACT_APP_GET_PLANS_DETAILS, REACT_APP_GET_SUBSCRIPTION_DETAILS, REACT_APP_GET_USER_STORAGE, REACT_APP_GET_USER_STORAGE_AND_PLAN, REACT_APP_SAVE_SUBSCRIPTION_DETAILS } from "../constants";

export const saveSubscription = async (sendToData, token) => {
    if (!token) {
        throw new Error("No access token found.");
    }

    try {
        const response = await axios.post(
            REACT_APP_SAVE_SUBSCRIPTION_DETAILS, sendToData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                // data: {  },  
            }
        );

        return response.data;

    } catch (error) {
        throw new Error(`HTTP error! Status: ${error.response.status}`);
    }
};

export const cancelRequestSubscription = async (email, token) => {

    if (!token) {
        throw new Error("No access token found.");
    }

    try {
        // const response = await axios.get(`${REACT_APP_GET_TRANSACTION_DETAILS} ${paymentId}`);
        const response = await axios.post(
            REACT_APP_CANCELED_SUBSCRIPTION,
            {
                email
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data; // Return transaction details
    } catch (error) {
        throw new Error(
            error.response?.data?.error || "Failed to fetch transaction details."
        );
    }

};

export const getPlans = async (token) => {
    if (!token) {
        // throw new Error("No access token found.");
        return [];
    }
    try {
        const response = await axios.get(REACT_APP_GET_PLANS_DETAILS, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            // data: {  },  
        });
        return response.data; // Assuming the API returns an array of plans
    } catch (error) {
        console.error("Error fetching plans:", error);
        throw error;
    }
};

export const getSubscriptionByIdentifier = async (identifier, token) => {
    if (!token) {
        throw new Error("No access token found.");
    }
    try {
        const response = await axios.get(`${REACT_APP_GET_SUBSCRIPTION_DETAILS}${identifier}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            // data: {  },  
        });
        return response.data; // Assuming the API returns an array of plans
    } catch (error) {
        console.error("Error fetching plans:", error);
        throw error;
    }
};

export const getUserStorageAndPlan = async (email, token) => {

    if (!token) {
        throw new Error("No access token found.");
    }

    try {
        const response = await axios.post(
            REACT_APP_GET_USER_STORAGE_AND_PLAN,
            {
                email
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data; // Return transaction details
    } catch (error) {
        throw new Error(
            error.response?.data?.error || "Failed to fetch transaction details."
        );
    }

};

export const getUserStorage = async (email, token) => {

    if (!token) {
        throw new Error("No access token found.");
    }

    try {
        const response = await axios.post(
            REACT_APP_GET_USER_STORAGE,
            {
                email
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data; // Return transaction details
    } catch (error) {
        throw new Error(
            error.response?.data?.error || "Failed to fetch transaction details."
        );
    }

};
