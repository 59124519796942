import React, { useContext, useState } from "react";

import "./ProfilePage.css";
import ProfileInfo from "./components/ProfileInfo/ProfileInfo";
import CurrentPlan from "./components/CurrentPlan/CurrentPlan";
import BillingHistory from "./components/BillingHistory/BillingHistory";

import Tabs from "./components/Tabs/Tabs";
import HeaderComponent from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { UserContext } from "../../providers/UserContext";
import images from "../../images";

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const { currentUser } = useContext(UserContext);

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return <ProfileInfo />;
      case "currentPlan":
        return <CurrentPlan />;
      case "billingHistory":
        return <BillingHistory />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="min-screen recordingContent">
        <HeaderComponent />
        <div className="mainContentBox profile-maincontentbox">
          <div className="card">
            <div className="card-body">
              <div className="profile-page">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="profile-work">
                      <h3>
                        {currentUser?.first_name?.toUpperCase() || ""}{" "}
                        {currentUser?.last_name?.toUpperCase() || ""}
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="profile-content">{renderContent()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProfilePage;
