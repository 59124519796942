import React, { useContext, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import HeaderComponent from "components/Header/Header";
import { FormContext } from "../../../providers/FormContext";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer/Footer";
import Select from "react-select";

const TranscriptType = () => {
  const { formData, updateFormData } = useContext(FormContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const ConversationOptions = [
    {
      value: "speech",
      label: "Speech",
    },
    {
      value: "conversation",
      label: "Addressing an Individual",
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)", // Closer to dark blue in your image
      borderRadius: "10px", // Slightly more rounded corners
      borderColor: "rgba(255, 255, 255, 0.15)", // Soft white border
      color: "#fff",
      padding: "4px 8px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.2)", // Softer shadow for a subtle lift
      minHeight: "48px", // Adjust height as needed
      fontWeight: 400, // Regular font weight
      fontSize: "16px", // Standard text size
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)", // Matching dark blue with high transparency
      borderRadius: "10px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.25)", // Consistent shadow with control
      borderColor: "rgba(255, 255, 255, 0.15)",
      overflow: "hidden", // Ensures the rounded corners contain all child elements
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgb(120, 124, 137)"
        : "rgb(70, 75, 92)",
      color: "#FFF",
      padding: "15px 20px",
      margin: "8px",
      maxWidth: "98%",
      borderRadius: "15px",
      // Generous padding for a spacious feel
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FFF",
    }),
    input: (provided) => ({
      ...provided,
      cursor: "default", // Prevent text cursor appearance
      color: "#FFF", // White text for input
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#CCC", // Placeholder text color for better contrast
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white", // Consistent white arrow
      "&:hover": {
        color: "rgba(255, 255, 255, 0.75)", // Slightly dim on hover for a dynamic effect
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Cleaner look without the separator
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px", // Remove padding to match your aesthetic requirements
    }),
  };

  const [conversationType, setConversationType] = useState(
    formData.conversation_type || ""
  );
  const [error, setError] = useState("");

  const handleTargetChange = (selectedOption) => {
    const value = selectedOption.value;
    setConversationType(value);
    updateFormData("conversationType", value);
  };

  const validateInput = () => {
    const length = parseInt(conversationType, 10);

    if (conversationType === "") {
      setError("Please select a transcript type.");
      toast.error("Please select a transcript type.");
      return false;
    }

    setError("");
    return true;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (validateInput()) {
      navigate(`/transcript-speaker/${id}`);
    }
  };

  const backClick = (e) => {
    e.preventDefault();
    navigate(`/transcript-generation/${id}`);
  };
  return (
    <div className="min-screen screen12">
      <HeaderComponent />
      <div className="mainContentBox">
        <div className="main-content">
          <h1 className="title">Transcript Generation</h1>
          <p className="des">
            Please select the transcript type to help us tailor your
            transcription accurately. Choosing the appropriate type will enhance
            the clarity and relevance of the final transcript.
          </p>
          <form className="form_" onSubmit={handleNextClick}>
            <label className="form-label">
              Select the transcript type for tailored, accurate results
              {/* <span className="text-danger">*</span> */}
            </label>
            <Select
              options={ConversationOptions}
              onChange={handleTargetChange}
              placeholder="Select conversation type"
              styles={customStyles}
              defaultValue={
                formData.conversationType
                  ? {
                      value: formData.conversationType,
                      label: formData.conversationType,
                    }
                  : null
              }
              isSearchable={false}
            />
          </form>
          {error && (
            <div className="trans-error-message">
              <span>{error}</span>
            </div>
          )}
          <div className="btn-list">
            <Link className="noDecoration">
              <button className="small" onClick={backClick}>
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                </svg>
                Back
              </button>
            </Link>
            <Link className="noDecoration">
              <button className="small green-btn" onClick={handleNextClick}>
                next
                <svg
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TranscriptType;
