import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "../../../components/Header/Header";
import { toast } from "react-toastify";
import { getVocabById } from "../../../services/user.service";
import languagesData from "../../../language.json";
import Select from "react-select";
import ModalComponent from "../../../components/Modal/VocabModal/VocabModal";
import { useDispatch } from "react-redux";
import { setTempVocabulary, setVocabList } from "../../../redux/vocabSlice";
import Footer from "../../../components/Footer/Footer";
import {
  editgenerateVocabulary,
  generateTopicDetails,
  generateVocabulary,
} from "../../../services/vocab.service";
import { UserContext } from "../../../providers/UserContext";
import ErrorModalComponent from "../../../components/Modal/TryAgainModal/TryAgainModal";
import { REACT_APP_MAX_COUNT_FOR_CONTENT_GENERATE } from "../../../constants";

const EditVocabulary = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useContext(UserContext);
  // console.log("id:", id);
  const [showErorModal, setShowErosModal] = useState(false);

  const [activeStep, setActiveStep] = useState("vocabulary-terms");
  const [oldTopic, setOldTopic] = useState("");
  const [apiError, setApiError] = useState("");
  const [vocabularyDetails, setVocabularyDetails] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showTopicModal, setShowTopicModal] = useState(false);

  const steps = [
    {
      primaryStep: true,
      stepName: "vocabulary-terms",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `To create a tailored set of vocabulary flashcards for your interpreter practice, please answer the following questions. You can skip any question if you prefer, but more details will help us create a more precise practice experience.`,
    },
    {
      primaryStep: false,
      stepName: "select-language",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `Please select the source and target languages to generate your vocabulary list.`,
    },
    {
      primaryStep: false,
      stepName: "topic",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `Providing detailed information on your topic will result in more detailed flashcards. For example, if your topic is a doctor's visit, include details such as the reason for the visit and relevant information about the patient. The more specific you are, the better the results!`,
    },
    {
      primaryStep: false,
      stepName: "topic_additional_information",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `Maximize the power of your flashcards by sharing as many additional details as possible below.`,
    },
    {
      primaryStep: false,
      stepName: "language-level",
      component: "",
      heading: "Vocabulary Flashcards for Interpreter Practice",
      content: `How challenging should it be? Please select from the provided difficulty levels to ensure the vocabulary list matches your current language proficiency and learning goals.`,
    },
    // {
    //   primaryStep: false,
    //   stepName: "geographical-area",
    //   component: "",
    //   heading: "Vocabulary Flashcards for Interpreter Practice",
    //   content: `Please specify the geographical region where this speech is most commonly given. This information will help us tailor the transcript to better reflect local dialects, terminologies, and cultural context.`,
    // },
  ];
  const closeModal = () => {
    // handleNext();
    setShowErosModal(false);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)",
      borderRadius: "10px",
      borderColor: "rgba(255, 255, 255, 0.15)",
      color: "#fff",
      padding: "4px 8px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.2)",
      minHeight: "48px",
      fontWeight: 400,
      fontSize: "16px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(18, 38, 63, 0.9)",
      borderRadius: "10px",
      boxShadow: "0 3px 15px rgba(0, 0, 0, 0.25)",
      borderColor: "rgba(255, 255, 255, 0.15)",
      overflow: "hidden",
      zIndex: 9999, // Ensures it appears above other elements
      position: "absolute", // Keeps it properly positioned
      pointerEvents: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "250px",
      maxWidth: "auto", // Adjust as needed for dropdown size
      overflowY: "auto",
      overflowX: "auto", // Enables scrolling for long lists
      padding: "0px",
      WebkitOverflowScrolling: "touch",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "rgb(120, 124, 137)"
        : "rgb(70, 75, 92)",
      color: "#FFF",
      padding: "15px 20px",
      margin: "8px",
      maxWidth: "540px",
      borderRadius: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FFF",
    }),
    input: (provided) => ({
      ...provided,
      color: "#FFF",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#CCC",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "rgba(255, 255, 255, 0.75)",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  useEffect(() => {
    const getVocab = async () => {
      const payload = { identifier: id };

      try {
        const response = await getVocabById(payload, currentUser.access);
        console.log(
          "Raw topic_additional_information:",
          response.keyword.additional_info
        );

        let parsedTopicAdditionalInformation = [];
        if (typeof response.additional_info === "string") {
          try {
            // Preprocessing: Fixing common JSON issues
            // const cleanedString = response.additional_info
            //   .replace(/([{,])(\s*[^":\s]+)\s*:/g, '$1"$2":') // Add quotes around keys
            //   .replace(/,(\s*})/g, '$1'); // Remove trailing commas

            parsedTopicAdditionalInformation = response.additional_info;
            // parsedTopicAdditionalInformation = [
            //       {
            //         "label": "Geographic Area",
            //         "placeholder":  "Is the focus on a specific region, such as the Arctic, a desert, or a coastal area?",
            //         "value": ""
            //       },
            //       {
            //         "label": "Geographic Area 2",
            //         "placeholder":  "Is the focus on a specific region, such as the Arctic, a desert, or a coastal area?",
            //         "value": ""
            //       },
            //       {
            //         "label": "Geographic Area 3",
            //         "placeholder":  "Is the focus on a specific region, such as the Arctic, a desert, or a coastal area?",
            //         "value": ""
            //       }
            //     ];
          } catch (error) {
            console.error(
              "Failed to parse topic_additional_information after cleaning:",
              error
            );
            parsedTopicAdditionalInformation = [];
          }
        } else {
          parsedTopicAdditionalInformation = response.additional_info;
        }
        setOldTopic(response.keyword.topic);
        setVocabularyDetails({
          ...response.keyword,
          topic_additional_information: parsedTopicAdditionalInformation,
        });
      } catch (error) {
        console.error("Error fetching vocabulary details:", error);
      }
    };

    if (currentUser && currentUser.access) {
      getVocab();
    }
  }, [id, currentUser]);

  const languageOptions = languagesData.map((language) => ({
    value: language.fullname,
    label: language.fullname,
  }));

  const getCurrentStepIndex = () => {
    return steps.findIndex((step) => step.stepName === activeStep);
  };

  const handleNext = async () => {
    setShowErosModal(false);

    const currentIndex = getCurrentStepIndex();
    if (activeStep === "vocabulary-terms") {
      const count = parseInt(vocabularyDetails.num_keywords, 10);

      if (vocabularyDetails.num_keywords === "") {
        toast.error("Vocabulary count cannot be empty.");
        return false;
      }

      if (isNaN(count)) {
        toast.error("Please enter a valid number.");
        return false;
      }

      if (count <= 4 || count > 25) {
        toast.error(
          "Please enter a number greater than or equal to 5 and less than or equal to 25."
        );
        return false;
      }
    } else if (activeStep === "select-language") {
      if (
        !vocabularyDetails.source_language ||
        !vocabularyDetails.target_language
      ) {
        toast.error("Please select both source and target languages.");
        return false;
      }
    } else if (activeStep === "topic") {
      if (!vocabularyDetails.topic) {
        toast.error("Please enter a topic.");

        return false;
      }

      // Validate word count
      const wordCount = vocabularyDetails.topic
        .trim()
        .split(/\s+/)
        .filter((word) => word !== "").length;
      if (wordCount < 5) {
        toast.error("The topic must contain at least 5 words.");
        return false;
      }

      if (oldTopic !== vocabularyDetails.topic) {
        setShowTopicModal(true);
        try {
          const result = await generateTopicDetails(
            currentUser.access,
            vocabularyDetails.topic
          );
          if (result && result.questions) {
            setVocabularyDetails({
              ...vocabularyDetails,
              topic_additional_information: result.questions,
            });
            setShowTopicModal(false);
          }
        } catch (error) {
          return false;
        }
      }
    } else if (activeStep === "language-level") {
      if (!vocabularyDetails.level) {
        toast.error("Please select a difficulty level.");
        return false;
      }
    } else if (activeStep === "geographical-area") {
      if (!vocabularyDetails.region) {
        toast.error("Please specify the geographical region.");
        return;
      }
    }

    if (currentIndex < steps.length - 1) {
      setActiveStep(steps[currentIndex + 1].stepName);
    }
    if (currentIndex === steps.length - 1) {
      setShowModal(true);
      setApiError("");

      // const token = localStorage.getItem("accessToken");
      if (!currentUser) {
        console.error("No access token found");
        setShowModal(false);
        return;
      }

      const maxRetries = 2; // Maximum number of retries
      let attempts = 0; // Number of attempts made
      let success = false;
      const adjustedVocabularyDetails = {
        ...vocabularyDetails,
        num_keywords: parseInt(vocabularyDetails.num_keywords, 10),
      };

      while (attempts < maxRetries && !success) {
        try {
          const data = await editgenerateVocabulary(adjustedVocabularyDetails);

          if (
            data &&
            data.keywords &&
            data.keywords.keywords &&
            Array.isArray(data.keywords.keywords.lists)
          ) {
            console.log(
              "data.keywords.keywords.lists",
              data.keywords.keywords.lists[0].length
            );
            const keywords = [
              {
                prev: false,
                keyword: data.keywords.keywords.lists[0],
                next: false,
              },
            ];
            dispatch(setVocabList(keywords));
            success = true;
            toast.success("Vocabulary Generated Successfully.");
            navigate(`/vocabulary/keywords/${id}`, {
              state: { vocabularyDetails: vocabularyDetails },
            });
            break;
          } else {
            // setShowModal(false);

            // setShowErosModal(true);
            console.error("Invalid data format", data);
          }
          break;
        } catch (error) {
          // setShowModal(false);
          // setShowErosModal(true);
          console.error(
            `API call failed (Attempt ${attempts + 1}/${maxRetries}):`,
            error
          );
          attempts += 1;

          if (attempts >= maxRetries) {
            setApiError(
              "Error in Generating. Go back to Dashboard and please try again later."
            );
            toast.error(
              "Error in Generating. Go back to Dashboard and please try again later."
            );
            setShowModal(false);

            setShowErosModal(true);
          } else {
            console.log("Retrying the API call...");
          }
        }
      }
    }
  };

  const handleBack = () => {
    const currentIndex = getCurrentStepIndex();
    if (currentIndex > 0) {
      setActiveStep(steps[currentIndex - 1].stepName);
    }
  };
  const handleChange = (selectedOption, field) => {
    console.log(field, selectedOption); // Log the value and field

    // Always update the state regardless of whether selectedOption is empty or not
    setVocabularyDetails((prevDetails) => ({
      ...prevDetails,
      [field]: selectedOption,
    }));

    // console.log("Updated vocabularyDetails:", vocabularyDetails); // Check the updated state
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setVocabularyDetails((prev) => ({
      ...prev,
      topic_additional_information: prev.topic_additional_information.map(
        (item) =>
          item.label.toLowerCase().replace(/ /g, "_") === name
            ? { ...item, value }
            : item
      ),
    }));
  };

  return (
    <div className="min-screen screen3">
      <HeaderComponent />
      {showModal && (
        <ModalComponent modalText="Generating vocabulary. Please wait." />
      )}
      {showTopicModal && (
        <ModalComponent modalText="Processing your topic. Please wait." />
      )}
      {steps.map(
        (step) =>
          activeStep === step.stepName && (
            <div className="mainContentBox" key={step.stepName}>
              <div className="main-content">
                <h1 className="title">{step.heading}</h1>
                <p className="des">{step.content}</p>
                <form className="form_">
                  {activeStep === "vocabulary-terms" && (
                    <>
                      <label className="form-label">
                        How many vocabulary terms do you want to drill in this
                        practice?
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="number"
                        value={vocabularyDetails.num_keywords}
                        onChange={(e) =>
                          handleChange(e.target.value, "num_keywords")
                        }
                        className="form-control"
                        placeholder="(Ex: 15)"
                      />
                    </>
                  )}
                  {activeStep === "select-language" && (
                    <>
                      {/* <label className="form-label">
                        In what language combination?{" "}
                        <span className="text-danger">*</span>
                      </label> */}
                      <div className="row">
                        <div className="col-md-6 select-language mb-3">
                          <Select
                            options={languageOptions}
                            onChange={(e) =>
                              handleChange(e.value, "source_language")
                            }
                            placeholder="Select source language"
                            styles={customStyles}
                            value={{
                              value: vocabularyDetails.source_language,
                              label: vocabularyDetails.source_language,
                            }}
                            // defaultValue={vocabularyDetails.source_language}
                          />
                        </div>
                        <div className="col-md-6">
                          <Select
                            options={languageOptions}
                            onChange={(e) =>
                              handleChange(e.value, "target_language")
                            }
                            placeholder="Select target language"
                            styles={customStyles}
                            value={{
                              value: vocabularyDetails.target_language,
                              label: vocabularyDetails.target_language,
                            }}
                            // defaultValue={{ value: vocabularyDetails.target_language, label: vocabularyDetails.target_language}}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {activeStep === "topic" && (
                    <>
                      <label className="form-label">
                        What topic would you like your flashcards to focus on?{" "}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="form-control"
                        value={vocabularyDetails.topic}
                        onChange={(e) => handleChange(e.target.value, "topic")}
                        placeholder="Please provide at least 5 words to describe your topic."
                      ></textarea>
                      {/* <p className="hint-text">
                        Please provide at least three words that best describe
                        your topic.
                      </p> */}
                    </>
                  )}

                  {activeStep === "topic_additional_information" && (
                    <>
                      {Array.isArray(
                        vocabularyDetails.topic_additional_information
                      ) &&
                        vocabularyDetails.topic_additional_information.map(
                          (item, index) => (
                            <div key={index} className="form-group">
                              <label className="form-label">{item.label}</label>
                              {/* <input
                                type="text"
                                className="form-control"
                                name={item.label
                                  .toLowerCase()
                                  .replace(/ /g, "_")} // Generate a unique name
                                placeholder={`Enter ${item.placeholder.toLowerCase()}`}
                                value={item.value}
                                onChange={handleInputChange}
                              /> */}
                              <textarea
                                rows="2"
                                cols="50"
                                className="form-control mb-2"
                                onChange={handleInputChange}
                                name={item.label
                                  .toLowerCase()
                                  .replace(/ /g, "_")} // Generate a unique name
                                placeholder={item.placeholder}
                                value={item.value}
                              ></textarea>
                            </div>
                          )
                        )}
                    </>
                  )}

                  {activeStep === "language-level" && (
                    <>
                      <label className="form-label mb-4">
                        How challenging should it be?{" "}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <div className="input-box">
                        <label className="custom-check form-label">
                          Beginner
                          <input
                            type="radio"
                            name="level"
                            value="beginner"
                            checked={vocabularyDetails.level === "beginner"}
                            onChange={(e) => handleChange("beginner", "level")}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="input-box">
                        <label className="custom-check form-label">
                          Intermediate
                          <input
                            type="radio"
                            name="level"
                            value="intermediate"
                            checked={vocabularyDetails.level === "intermediate"}
                            onChange={(e) =>
                              handleChange("intermediate", "level")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="input-box">
                        <label className="custom-check form-label">
                          Advanced
                          <input
                            type="radio"
                            name="level"
                            value="advanced"
                            checked={vocabularyDetails.level === "advanced"}
                            onChange={(e) => handleChange("advanced", "level")}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </>
                  )}
                  {activeStep === "geographical-area" && (
                    <>
                      <label className="form-label">
                        What area of the world would this speech most commonly
                        be given?
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <textarea
                        rows="3"
                        cols="50"
                        className="form-control"
                        value={vocabularyDetails.region}
                        onChange={(e) => handleChange(e.target.value, "region")}
                        placeholder="Please enter the geographical region where this speech is most commonly given."
                      ></textarea>
                      {apiError && <p className="text-danger">{apiError}</p>}
                    </>
                  )}
                </form>
                <div
                  className={`btn-list ${
                    activeStep === "topic_additional_information" ? "mb-5" : ""
                  }`}
                >
                  <button
                    className="btn small"
                    onClick={handleBack}
                    disabled={getCurrentStepIndex() === 0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                    </svg>
                    Back
                  </button>

                  <button className="btn small  green-btn" onClick={handleNext}>
                    next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )
      )}
      <Footer />
      {showErorModal && (
        <ErrorModalComponent
          modalText="Something went wrong! Please try again."
          onClose={closeModal}
          onClickTryAgain={handleNext}
        />
      )}
    </div>
  );
};

export default EditVocabulary;
